@import '../../styles/mixins';

.roadmap-action-item-page {
  min-height: 100vh;
  @extend .mrm-pb-4;

  .ai-description {
    overflow-wrap: break-word;
    @extend .mrm-py-1;

    img {
      max-width: calc(100% - 30px);
      margin: auto;
      display: block;
      border-radius: 10px;
    }
    .media {
      display: block;
    } 
  }

  .ai-notes {
    font-weight: 400;
    white-space: pre-line;
    @include media-breakpoint-up(lg) {
      @include mrm-white-on-white-box-shadow;
    }
  }

  #formNotes {
    @extend .mrm-mt-0_75;
  }

  .action-button {
    width: 15em;
  }
  
  button {
    @extend .mrm-mt-0_75;
  }
  
  .upload-progress {
    width: 8em;
  }

  @include media-breakpoint-up(lg) {
    .form-control {
      border-color: #cccccc;
      &:active, &:focus {
        border-color: #6E9FE0;
      }
    }
  }

  .approve-unapprove-btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    min-width: 9rem;
    @include media-breakpoint-down(lg) { 
      margin-top: 0px !important;
    }
  }

}


.mrm-mt-0_75, .user-components-profile-view .coach-card, .roadmap-action-item-page #formNotes, .roadmap-action-item-page button, .chat-list-row:nth-of-type(1) {
    margin-top: 2px!important;
}

.rh5v-PlayPause_button {
    width: 50px!important;
    height: 50px!important;
   
}

.rh5v-Captions_button {
    width: 50px!important;
    height: 50px !important;
}

.rh5v-Volume_button {
    width: 50px!important;
    height: 50px!important;
  
}

.rh5v-Volume_icon {
    padding: 7px;
    width: 40px!important;
    height: 40px;
    margin-top: -20px;
}

.rh5v-Fullscreen_icon {
    padding: 5px;
    width: 40px;
    height: 40px;
    margin-top: -5px;
    margin-left: -5px;
}

.rh5v-PlayPause_icon {
    padding: 5px;
    width: 40px;
    height: 40px;
    margin-top: -20px;
    margin-left: -5px;
}