$color-assessment-yellow: #f5d844;
$color-assessment-red: #f1561e;
$color-assessment-green: #1bc573;
$color-assessment-none: #eeeeee;

$color-unread-red: red;
$color-unread-blue: #2f80ed;

$color-blue: #2f80ed;

$color-item-purple: #a768b7;
$color-item-lightpurple: #D0E0FF;
$color-item-blue: #2f80ed;
$color-item-yellow: #edc342;
$color-item-green: #23d37b;
$color-item-gray: #cccccc;

$color-text-normal: #3e3e3e;
$color-text-light: #727272;
$color-text-lighter: #8d8d8d;
$color-text-disabled: #aaaaaa;
$color-text-danger: #ff3b30;

$color-dark-text-normal: #bdc0c3;
$color-dark-text-dark: #e3e3e3;
$color-dark-text-light: #8d8d8d;
$color-dark-text-lighter: #727272;
$color-dark-text-disabled: #515151;

$color-background-light: #F6F6F7;
$color-background-dark: #2d3842;
$color-background-light-gray: #F4F4F5;

$color-input-dark-blue: #F1F2F3;
