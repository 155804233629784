.upcomingcalls-card {
  .more-btn-desktop {
    position: absolute;
    right: .7em;
    top: .7em;
  }
}

.coach-upcomingcalls-card {
  @include media-breakpoint-up(lg) {
    @include mrm-white-on-white-box-shadow;
  }
}