.svg-inline--fa {
  font-size: 1.5em;
}

// icon tile
.fa-4x {
  font-size: 4em;
}

// bottom nav
.fa-lg {
  font-size: 1.875em;
}

.fa-sm {
  font-size: 1.25em;
}

.fa-xs {
  font-size: 1.125em;
}

.fa-1x {
  font-size: 0.8em;
}
