@import '../../styles/mixins';

.manage-add-competency-action-item-page {
  .btn-save {
    color: $primary;
    background-color: transparent;
    box-shadow: none;
  }

  .btn-cancel {
    background-color: transparent;
    box-shadow: none;
  }
  .ck-editor__editable {
    min-height: get-scaled-size($scale-factor-12);
  }

  .ck-rounded-corners {
    .ck.ck-editor__top {
      .ck-sticky-panel {
        .ck-toolbar {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
      }
    }

    .ck.ck-editor__main {
      > .ck-editor__editable {
        min-height: get-scaled-size($scale-factor-12);

        &.ck-rounded-corners {
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .form-control {
      border-color: #cccccc;
      &:active, &:focus {
        border-color: #6E9FE0;
      }
    }
    .btn-cancel {
      background-color: #f4f4f5;
      box-shadow: none;
    }
    .btn-save {
      float: right;
      background: $color-item-blue;
      color: #fff;
    }
  }

  @include media-breakpoint-up(lg) {
    .normal-width-container {
      @include mrm-main-box-shadow;
      padding-bottom: 1rem;
      padding-left: 4rem;
      padding-right: 4rem;
      background: white;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    .assessment-slider-container {
      @include mrm-white-on-white-vivid-box-shadow;
      margin-top: 1rem;
      padding: 1rem;
      background-color: #fff;
      border-radius: 10px;
    }
    .card.item {
      .reorder {
        font-size: $font-size-normal;
        margin-right: 4px;
      }
    }
  }
}
