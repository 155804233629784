@import '../../styles/mixins';

.roadmap-note-page {
  .common-header {
    button {
      background-color: transparent;
      box-shadow: none;
      
      &:hover {
        opacity: .7;
      }
    }

    .btn-save {
      color: $primary;
    }
  }
}
