@import '../../../styles/mixins';

.dashboard-roadmap-card {
  @extend .mrm-white-on-white-box-shadow;

  .title {
    font-weight: bold;
    font-size: $font-size-small;
  }

  .progress {
    height: get-scaled-size($scale-factor-0_25);
    background-color: #DEDEDE;
    .progress-bar {
      background-color: #5F9CEF;
      transition: width 0.6s ease;
      border-radius: 4px;
    }
  }

  .roadmap-dot {
    position: absolute;
    left: -#{get-scaled-size($scale-factor-0_75)};
    transform: translateY(-50%);
    top: 50%;
  }

  .started-date {
    @extend .mrm-mt-1;
    font-size: $font-size-smaller;
    text-align: right;
    margin-bottom: 0;
  }
}
