@import '../../../styles/mixins';

.user-components-profile-form {
  hr {
    @extend .mrm-mb-1;
    width: calc(100% + 2rem);
    height: 1px;
    margin-left: -1rem;
  }

  form {
    .form-label {
      @extend .mrm-mb-0_25;
      @extend .mrm-ml-0_75;
    }
    .form-group {
      margin-bottom: get-scaled-size(1.3);
    }
  }

  .form-container {
    @include media-breakpoint-up(sm) {
      border-radius: 10px;
    }

    @include media-breakpoint-down(xs) {
      background-color: transparent;

      .btn-delete-account {
        background-color: white;
      }
    }
  }
  .profile-photo  {
    @include media-breakpoint-only(sm) {
      margin-top: 1rem;
    }
  }
  .desktop-save-cancel {
    .btn {
      flex-grow: 1;
    }
  }
}



.profile-edit-form-container {
  min-width: 350px;
}

.account-delete-modal {
  .confirm {
    max-width: 20em;
  }

  h1.title{
    @include media-breakpoint-down(xs) {
      font-size: $font-size-normal;
      margin: auto;
      line-height: 22px;
    }
  }

  .btn-cancel {
    @extend .mrm-pb-1_5;
    height: 30px;
  }

  .btn-delete-account {
    height: auto;
  }

  .input-sm {
    height: 30px;
    font-size: $font-size-smaller;
  }
}

@include media-breakpoint-up(sm) { 
  .user-components-profile-form { 
    .form-control {
      border: 1px solid $color-item-gray;
      &:focus {
        border: 1px solid #2f80ed;
      }
    }
    .form-container {
      @include mrm-main-box-shadow;
      margin-bottom: get-scaled-size($scale-factor-2_5)

    }
  }
}

@include media-breakpoint-only(sm) { 
  .user-components-user-avatar-editor {
    padding-top: 1.5rem;
  }
}

.account-change-password-modal {
  h1.title {
    @include media-breakpoint-down(xs) {
      font-size: $font-size-normal;
      margin: auto;
      line-height: 22px;
    }
  }

  .form-control {
    border-color: $color-item-gray;
  }

  .cancel-btn {
    @extend .mrm-mt-0_5;

    position: absolute;
    left: 0;
    top: 0;
    font-weight: bold;
  }
  .input-sm {
    height: 30px;
    font-size: $font-size-smaller;
  }

  .btn-primary:disabled {
    border-color: $color-text-disabled;
    background-color: $color-text-disabled;
  }
}
