@import '../../styles/mixins';

.home-forgot-password-page {
  @extend .mrm-pt-3;
  min-height: 100vh;
  
  .common-header {
    background-color: #ECF4FA;
  }

  .form-group {
    .svg-inline--fa {
      position: absolute;
      @extend .mrm-ml-0_5;
      @extend .mrm-mt-0_5;
      color: $primary;
    }
  }

  .form-control {
    @extend .mrm-pl-2_5;
  }

  .reset-instructions {
    color: $color-text-normal;
    font-size: $font-size-small;
  }

  .back-button-container {
    @extend .mrm-mb-3;
    @extend .mrm-pt-3;

    button {
      color: $color-text-normal;

      svg {
        @extend .mrm-mr-1;
        font-size: $font-size-large;
        vertical-align: middle;
      }
    }
  }
}
