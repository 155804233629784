@import '../../styles/mixins';

.common-search-bar {
  position: relative;

  .form-control {
    &:focus {
      border: 1px solid #EAEEF5;
    }
  }

  .search-icon {
    position: absolute;
    left: 12px;
    top: 12px;
    font-size: $font-size-small;
    color: $color-dark-text-lighter;
  }
}
