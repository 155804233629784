@import '../../styles/mixins';

table.common-custom-table {
  @extend .mrm-main-box-shadow;

  border-radius: 10px;
  background-color: white;
  width: 100%;

  thead {
    th {
      @extend .mrm-px-0_25;
      @extend .mrm-py-0_5;

      border-bottom: 1px solid $color-assessment-none;
      font-size: $font-size-normal;
      color: $color-text-light;
      white-space: nowrap;

      svg {
        @extend .mrm-ml-0_5;
        color: $color-text-light;
      }
    }

    th:not(:last-child) {
      border-right: none;
    }
  }

  tbody {
    tr {
      font-size: $font-size-small;

      td:first-child {
        color:  $color-text-normal;
      }

      td {
        @extend .mrm-p-0_25;
        color: $color-text-normal;
        vertical-align: middle;
        padding: 12px 5px;
      }

      td:not(:last-child) {
        border-right: none;
      }
    }
  }

  tr:not(:last-child) {
    border-bottom: 1px solid $color-assessment-none;
  }
}
