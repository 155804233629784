@import '../../styles/mixins';

.manage-groups-page {
  .btn-edit {
    background-color: $color-background-light-gray;
  }
  .common-custom-table{
    @include media-breakpoint-down(md) {
      border-radius: 0px;
    }
  }
}
