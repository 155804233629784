@import '../../styles/mixins';

.common-custom-dialog {
  .btn-cancel {
    color: $color-text-normal;
    
    &:focus {
      border: none;
    }

  }  
}
