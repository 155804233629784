@import '../../styles/mixins';

.dashboard-roadmaps-page {
  @extend .mrm-py-2, .mrm-px-1;
  @include media-breakpoint-down(md) {
    padding-top: .5rem;
  }
  min-height: 50vh;

  .default-roadmap-thumbnail {
    background-color: $color-item-lightpurple;
    border-radius: 10px;
    height: 70px;
    width: 70px;
    color: white;
  }

  .start-roadmap {
    color:#5F9CEF;
    font-size: $font-size-small;
    font-weight: bold;
    display: block;
    margin-top: -7px;
  }

  .progress-text {
    color: $color-text-light;
    font-size: $font-size-smaller;
  }

  .card-body {
    @extend .mrm-px-1;
    @extend .py-0
  }

  .card-img {
    border-radius: 10px;
    width: 70px;
    height: auto;
  }

  .card-title {
    font-size: $font-size-normal;
    font-weight: bold;
    @extend .mrm-mb-0_25;
  }

  .card span.small {
    color: $color-text-light;
  }

  .progress {
    height: 4px;
    border-radius: 2px;
    display: flex;
    overflow: hidden;
    background-color: #DEDEDE;
  }

  .progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    background-color: #5F9CEF;
    transition: width 0.6s ease;
    border-radius: 4px;
  }

  .assigned-coaches {
    @extend .mrm-mt-0_25;
    display: flex;
    flex-direction: row;
  }
}
