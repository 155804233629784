@import '../../../../styles/mixins';

.action-item-edit-menu-modal {
  .modal-dialog {
    .modal-content {
      background-color: transparent;
      border: none;
    }
  }
  .main-buttons {
    border-radius: 10px;
  }
  @include media-breakpoint-up(md) {
    .modal-dialog {
      display: flex;
      align-items: center;
      height: 100%;
    }
    .main-buttons {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .cancel {
      margin-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    .delete {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.roadmap-components-action-item-page-edit-menu {
  text-align: right;
}
