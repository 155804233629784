@import '../../styles/mixins';

.user-notifications-settings-page {
  .container {
    margin-top: 1rem;
  }

  .notification-settings-page-row {
    div:first-child {
      text-align: center;
    }
  }
}
