@import '../../styles/mixins';

.common-bottom-nav {
  .navbar {
    @extend .theme-bg;
    height: get-scaled-size($scale-factor-4);
    border-top: 1px solid $color-item-gray;

    span.badge {
      color: white;
      position: absolute;
      top: 58%;
      transform: translate(12%, -150%);
      font-size: get-scaled-size($scale-factor-0_5);
      line-height: 1.3;

      &.transparent-badge {
        color: transparent;
      }
    }

    .nav-link {
      color: $color-item-gray;
    }

    .nav-item {
      flex-basis: 0;
    }
  }
}

.#{$theme-dark} {
  .common-bottom-nav {
    .navbar {
      border-top: 1px solid #555;
    }
  }
}
