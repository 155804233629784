@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400&display=swap);
/*!
 * Bootstrap v4.5.2 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
         appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item {
  display: flex; }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem; }
    .breadcrumb-item + .breadcrumb-item::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #6c757d;
      content: "/"; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  .breadcrumb-item.active {
    color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
              animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: -webkit-min-content;
      height: -moz-min-content;
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@-webkit-keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
          animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@-webkit-keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
          animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  -webkit-user-select: all !important;
      -ms-user-select: all !important;
          user-select: all !important; }

.user-select-auto {
  -webkit-user-select: auto !important;
      -ms-user-select: auto !important;
          user-select: auto !important; }

.user-select-none {
  -webkit-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0,
.dashboard-roadmaps-page .card-body,
.dashboard-roadmap-library-page .card-body,
.roadmap-default-page .hidden-student {
  padding-top: 0 !important; }

.pr-0, .dashboard-roadmap-library-page .card-body,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0,
.dashboard-roadmaps-page .card-body,
.dashboard-roadmap-library-page .card-body,
.roadmap-default-page .hidden-student {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

html {
  font-size: 100%; }

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif; }

body,
#root,
.home-app,
.page-container {
  min-height: 100vh;
  width: 100vw; }

.page-container {
  padding-bottom: 1px; }

.container {
  width: 100vw; }

a {
  font-weight: bold;
  text-decoration: underline; }

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: 'Open Sans', sans-serif; }

h2 {
  font-size: 1.125rem;
  font-weight: bold; }

h3 {
  font-size: 1.1rem; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  height: 2px;
  background: #E5E5E5; }

button {
  border: none;
  background: none; }

.btn {
  font-weight: bold;
  border-radius: 10px;
  min-height: 40px;
  border: none;
  width: auto;
  font-size: .875rem; }
  .btn:focus {
    box-shadow: none; }

.btn-100 {
  width: 100%; }

.btn-center {
  display: block;
  margin: auto; }

.btn-orange {
  color: #fff;
  background-color: #f86630;
  border-color: #f86630; }
  .btn-orange:hover {
    color: #fff;
    background-color: #f86630;
    border-color: #ed4608; }
  .btn-orange:focus, .btn-orange.focus {
    color: #fff;
    background-color: #f86630;
    border-color: #ed4608;
    box-shadow: 0 0 0 0.2rem rgba(249, 125, 79, 0.5); }
  .btn-orange.disabled, .btn-orange:disabled {
    color: #fff;
    background-color: #f86630;
    border-color: #f86630; }
  .btn-orange:not(:disabled):not(.disabled):active, .btn-orange:not(:disabled):not(.disabled).active,
  .show > .btn-orange.dropdown-toggle {
    color: #fff;
    background-color: #ed4608;
    border-color: #e14208; }
    .btn-orange:not(:disabled):not(.disabled):active:focus, .btn-orange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-orange.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(249, 125, 79, 0.5); }

.btn-lg, .btn-group-lg > .btn {
  font-size: 0.875rem;
  height: 50px;
  width: 100%; }

.btn-secondary {
  background: transparent;
  color: #727272;
  font-weight: normal; }
  .btn-secondary:hover {
    cursor: pointer; }
  .btn-secondary:focus, .btn-secondary:active, .btn-secondary:hover {
    background: transparent !important;
    color: #727272 !important;
    box-shadow: none !important; }

.btn-destructive {
  background: transparent;
  color: #FF3B30;
  font-size: 1rem;
  font-weight: 400; }
  .btn-destructive:hover {
    color: #ff2317; }

.btn-menu-item {
  color: #3e3e3e;
  font-size: 1rem;
  font-weight: 400; }
  .btn-menu-item:focus {
    border: none; }
  .btn-menu-item:hover {
    color: #212529; }

.more-btn-desktop svg {
  background: #F4F4F5;
  border-radius: 8px;
  position: relative;
  color: #727272;
  font-size: 1.5rem;
  width: 40px !important;
  height: 40px;
  padding: 8px; }
  .more-btn-desktop svg:hover {
    opacity: 1;
    cursor: pointer;
    background-color: #efeff0; }

.more-btn-desktop:hover {
  opacity: 1; }

.confirmation-modal .modal-content .modal-title h1 {
  font-size: 1rem; }

.confirmation-modal h2 {
  font-size: 1rem;
  color: #3e3e3e; }

.confirmation-modal small {
  font-size: .875rem;
  color: #727272; }

.confirmation-modal .btn {
  font-size: 1rem; }

.confirmation-modal .btn-cancel {
  font-weight: 400;
  color: #3e3e3e; }
  .confirmation-modal .btn-cancel:hover {
    background-color: transparent;
    opacity: 1; }

@media (min-width: 992px) {
  .btn-cancel:hover {
    background-color: #efeff0;
    opacity: 1; } }

.btn-primary {
  background-color: #2f80ed;
  border-color: #2f80ed; }

.card-img {
  border-radius: 10px; }

.card-title {
  margin-bottom: 0; }

.border {
  height: 2px;
  width: 100%;
  background-color: #E5E5E5;
  border: none !important; }

.border-thin {
  height: 1px;
  width: 100%;
  background-color: #E5E5E5;
  border: none !important; }

.border-bottom {
  width: 100%;
  border-bottom: 1px solid #E5E5E5; }

.form-control {
  border-radius: 10px;
  height: 40px;
  border: 1px solid #eee;
  color: #3E3E3E; }
  .form-control:focus {
    box-shadow: none;
    border: 1px solid #2f80ed; }

.form-control:disabled {
  cursor: not-allowed; }

.input-group-text {
  border-radius: 10px;
  border: inherit; }

.form-label {
  color: #727272; }

.dropdown-menu {
  border-radius: 10px;
  border: none; }
  .dropdown-menu .form-check-input {
    margin-top: 0.25rem;
    width: 18px;
    height: 18px; }

.form-check, .form-check-label, .form-check-input {
  cursor: pointer; }

.dot {
  height: 8px;
  width: 8px;
  background-color: #2f80ed;
  border-radius: 50%;
  display: inline-block; }

input,
textarea,
[contenteditable] {
  caret-color: #3e3e3e; }

.card {
  border-radius: 10px;
  border: none; }

.desktop-page-container .form-control {
  border: 1px solid #cccccc; }

.desktop-page-secondary-header-wrapper {
  background: #fff;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.initials {
  height: 35px;
  width: 35px;
  display: flex;
  background-color: #a768b7;
  border-radius: 50%;
  margin-right: 5px; }
  .initials span {
    color: #ffffff;
    font-size: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 200; }
  .initials.md {
    height: 47px;
    width: 47px; }
    .initials.md span {
      font-size: 18px; }
  .initials.lg {
    height: 80px;
    width: 80px; }
    .initials.lg span {
      font-size: 32px; }

.navbar a:hover {
  opacity: 1; }

.bg-grey {
  background-color: #f6f8f9; }

.modal-content {
  border-radius: 10px;
  border: none; }
  .modal-content .modal-header {
    border-bottom: 1px solid #E5E5E5;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px; }
  .modal-content .modal-title {
    text-align: center; }
    .modal-content .modal-title h1 {
      width: 100%;
      font-size: 1.125rem;
      font-weight: bold;
      margin-bottom: 0px;
      line-height: 24px; }
    .modal-content .modal-title p {
      color: #727272;
      font-size: 1rem; }
  .modal-content .btn {
    min-height: 45px; }
  @media (min-width: 992px) {
    .modal-content .form-control {
      border: 1px solid #cccccc; }
      .modal-content .form-control:focus {
        box-shadow: none;
        border: 1px solid #2f80ed; } }

.modal-mobile-slide-from-bottom .modal-content {
  margin-bottom: 1.5rem; }

.modal-mobile-slide-from-bottom .btn-menu-item {
  height: 50px; }

@media (max-width: 575.98px) {
  .modal-mobile-slide-from-bottom .modal-dialog {
    display: flex;
    align-items: flex-end;
    min-height: calc(100% - 1rem); }
  .modal-mobile-slide-from-bottom.fade .modal-dialog {
    transform: translateY(50px); }
  .modal-mobile-slide-from-bottom.show .modal-dialog {
    transform: none; } }

.border-rounded {
  border-radius: 10px; }

input[type=checkbox] {
  position: relative; }
  input[type=checkbox].is-invalid::after {
    border: 1px solid #dc3545; }
  input[type=checkbox]::after {
    content: '';
    width: 1.4rem;
    height: 1.4rem;
    position: absolute;
    border-radius: 30%;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    background-color: white;
    border: 1px solid #cccccc; }
    @media (max-width: 991.98px) {
      input[type=checkbox]::after {
        border: 1px solid #eeeeee; } }
  input[type=checkbox]:checked::after {
    background-color: #2f80ed;
    border: 1px solid #2f80ed; }
  input[type=checkbox]:checked::before {
    content: '';
    background-image: url(https://s.w.org/images/core/emoji/13.0.0/svg/2714.svg);
    width: 0.7rem;
    height: 0.7rem;
    -webkit-filter: brightness(9);
            filter: brightness(9);
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    color: white;
    z-index: 4;
    position: absolute; }
  input[type=checkbox] ~ label {
    margin-left: 0.75em; }

a {
  text-decoration: none; }
  a:hover {
    opacity: .7;
    text-decoration: none; }
  a.no-format {
    color: inherit;
    font-weight: inherit; }
    a.no-format:hover {
      opacity: 1; }

.pagination-container .pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
  justify-content: center; }
  .pagination-container .pagination > .active > a,
  .pagination-container .pagination > .active span {
    color: #2f80ed;
    background-color: rgba(47, 128, 237, 0.15);
    border-color: transparent;
    outline: none; }
  .pagination-container .pagination > li:first-child a,
  .pagination-container .pagination > li:first-child span {
    border-radius: none; }
  .pagination-container .pagination > li > a {
    border: none;
    padding: 5px 10px;
    outline: none;
    cursor: pointer; }
  .pagination-container .pagination > li a,
  .pagination-container .pagination > li span {
    text-transform: capitalize;
    color: #2f80ed; }
    .pagination-container .pagination > li a:hover, .pagination-container .pagination > li a:active, .pagination-container .pagination > li a:focus,
    .pagination-container .pagination > li span:hover,
    .pagination-container .pagination > li span:active,
    .pagination-container .pagination > li span:focus {
      color: #2f80ed; }

.infinite-scroll-loader {
  text-align: center;
  margin-top: 25px;
  width: 100%;
  flex-basis: 100%; }

.theme-bg, .page-container, .common-header, .common-bottom-nav .navbar, .tab-selector, .roadmap-competency-page .comment-list .comment-form, .msg-room .msg-form {
  background-color: #F6F6F7; }

.theme-text-normal, .text-normal {
  color: #3e3e3e; }

.theme-text-dark, .tab-selector .nav-link.active, .text-dark {
  color: #3e3e3e; }

.theme-text-light, .text-light {
  color: #727272; }

.theme-text-lighter, .text-lighter {
  color: #8d8d8d; }

.theme-text-disabled, .text-disabled {
  color: #aaaaaa; }

.dark .theme-bg, .dark .page-container, .dark .common-header, .dark .common-bottom-nav .navbar, .common-bottom-nav .dark .navbar, .dark .tab-selector, .dark .roadmap-competency-page .comment-list .comment-form, .roadmap-competency-page .comment-list .dark .comment-form, .dark .msg-room .msg-form, .msg-room .dark .msg-form {
  background-color: #2d3842; }

.dark .theme-text-normal {
  color: #bdc0c3; }

.dark .theme-text-dark, .dark .tab-selector .nav-link.active, .tab-selector .dark .nav-link.active {
  color: #e3e3e3; }

.dark .theme-text-light {
  color: #8d8d8d; }

.dark .theme-text-lighter {
  color: #727272; }

.dark .theme-text-disabled {
  color: #515151; }

/* Legacy start */
h1, h2, h3 {
  color: #3A3A3B; }

.blue {
  background-color: #ECF4FA; }

.theme-text {
  color: #555; }

.theme-text-blue {
  color: #2f80ed; }

.color-primary {
  color: #3e3e3e; }

.color-secondary {
  color: #727272; }

.theme-text-primary {
  color: #3e3e3e; }

a.theme-text-primary:hover {
  color: #777; }

.theme-text-secondary {
  color: #727272; }

.theme-card {
  background-color: white; }

a.theme-text-secondary:hover {
  color: #999; }

.dark .theme-text {
  color: #aaa; }

.dark .theme-text-primary {
  color: #eee; }

.dark a.theme-text-primary:hover {
  color: #cccccc; }

.dark .theme-text-secondary {
  color: #999; }

.dark a.theme-text-secondary:hover {
  color: #777; }

.dark .blue {
  background-color: #2d3842; }

.dark h1, .dark h2, .dark h3 {
  color: white; }

.dark .border, .dark .border-thin {
  background-color: #555; }

.btn-white {
  background-color: white;
  color: #727272; }
  .btn-white:focus, .btn-white:active, .btn-white:hover {
    background-color: white;
    color: #727272; }

.btn-gray {
  background-color: #F4F4F5;
  color: #727272; }
  .btn-gray:focus, .btn-gray:active, .btn-gray:hover {
    color: #727272;
    background-color: #ececee;
    opacity: 1; }

.css-xb97g8:hover {
  cursor: pointer; }

.css-g1d714-ValueContainer .css-1rhbuit-multiValue {
  border-radius: 6px; }
  .css-g1d714-ValueContainer .css-1rhbuit-multiValue .css-12jo7m5 {
    font-size: 1rem; }

.delete-comment-message-icon {
  color: #aaaaaa;
  font-size: .75rem; }
  .delete-comment-message-icon:hover {
    color: #b2b2b2;
    cursor: pointer; }

.rc-slider-disabled {
  background-color: transparent; }

figure iframe {
  border-radius: 4px; }

.hover:hover {
  cursor: pointer; }

.react-datepicker__input-container {
  width: auto;
  width: initial; }

.react-datepicker-wrapper {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.fadein, .new-message-modal .selected-participants .participant {
  animation: fadein 1s;
  -moz-animation: fadein 1s;
  /* Firefox */
  -webkit-animation: fadein 1s;
  /* Safari and Chrome */
  -o-animation: fadein 1s;
  /* Opera */ }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.svg-inline--fa {
  font-size: 1.5em; }

.fa-4x {
  font-size: 4em; }

.fa-lg {
  font-size: 1.875em; }

.fa-sm {
  font-size: 1.25em; }

.fa-xs {
  font-size: 1.125em; }

.fa-1x {
  font-size: 0.8em; }

/*  Spacing ****************************
**
**
**  All spacing sre based on $scale-factor-0_25 ~ $scale-factor-48
**
**  Padding and margin classes are defined like
**  .mrm-p-0_25, .mrm-p_4, .mrm-px_1_5, .mrm-py_2
**  .mrm-m-0_25, .mrm-m_4, .mrm-mx_1_5, .mrm-my_2
**
**  We can get custom spacing using get-scaled-size function defined in this scss file
**
**
**  Colors and theming *****************
**
**
**  All colors are defined in colors.scss.
**
**  In theme.scss, text color classes are defined based on each color variables like
**  .text-normal, .text-dark, .text-light, .text-lighter, .text-disabled
**
**  If you want to change text colors according to the theme, we can use theme text classes like
**  .theme-text-normal, .theme-text-dark, .theme-text-light, .theme-text-lighter, .theme-text-disabled
**  and their colors are automatically reverted in dark theme
**
**  .theme-bg classes can be used to revert background color according to the applied theme
**  We need to gradually get rid of legacy theme, which starts with comment "Legacy start" in theme.scss
**
*/
.mrm-m-0_25 {
  margin: 0.25rem; }

.mrm-mx-0_25 {
  margin-left: 0.25rem;
  margin-right: 0.25rem; }

.mrm-my-0_25 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem; }

.mrm-mt-0_25, .user-user-page .user-page-header p:last-child a, .dashboard-roadmaps-page .assigned-coaches, .roadmap-competency-page .competency-comment .card, .no-messages p {
  margin-top: 0.25rem; }

.mrm-mr-0_25, .item-count svg, .item-count img, .roadmap-default-page .card-text .svg-inline--fa, .roadmap-competency-page h2, .notifications-components-default-page-notifications-list .notification-row .unread-marker {
  margin-right: 0.25rem; }

.mrm-mb-0_25, .tab-selector, .user-components-profile-form form .form-label, .dashboard-roadmaps-page .card-title, .roadmap-competency-page .desktop-header-container h2, .roadmap-components-competency-page-competency-learn-tab .questions-section .question-item .answer, .msg-list .msg-list-row .msg-detail .user-info {
  margin-bottom: 0.25rem; }

.mrm-ml-0_25, .common-desktop-header .row .col.right-menu .menu-item-container .btn svg, .dashboard-admin-page .sort.sort-desktop span, .dashboard-admin-page .sort.sort-desktop svg, .roadmap-default-page .default-stage-thumbnail.left-edge, .roadmap-competency-page .competency-comment .user-name, .roadmap-competency-page .desktop-header-container h2 small {
  margin-left: 0.25rem; }

.mrm-m-0_5 {
  margin: 0.5rem; }

.mrm-mx-0_5, .new-message-modal .selected-participants .participant {
  margin-left: 0.5rem;
  margin-right: 0.5rem; }

.mrm-my-0_5, .user-components-user-avatar-editor .upload-photo {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }

.mrm-mt-0_5, .home-log-in-page .form-group .svg-inline--fa, .home-sign-up-page .form-group .svg-inline--fa, .home-forgot-password-page .form-group .svg-inline--fa, .account-change-password-modal .cancel-btn, .item-count, .roadmap-competency-page .competency-comment .initials, .roadmap-competency-page .attachment-button, .roadmap-competency-page .desktop-header-container h2, .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-slider-container p, .roadmap-components-competency-page-competency-learn-tab .questions-section .question-item .answer, .msg-list {
  margin-top: 0.5rem; }

.mrm-mr-0_5, .item-count, .msg-list .msg-list-row .msg-detail .user-info h5, .manage-edit-user-roadmap-modal .modal-header .common-user-avatar {
  margin-right: 0.5rem; }

.mrm-mb-0_5, .roadmap-components-competency-page-competency-learn-tab .notes-container .notes-description a pre, .roadmap-components-competency-page-competency-learn-tab .notes-container pre, .manage-user-page .info div {
  margin-bottom: 0.5rem; }

.mrm-ml-0_5, .home-log-in-page .form-group .svg-inline--fa, .home-sign-up-page .form-group .svg-inline--fa, .home-forgot-password-page .form-group .svg-inline--fa, .common-user-not-approved a, table.common-custom-table thead th svg, .roadmap-default-page .hidden-student, .roadmap-competency-page .competency-comment .user-name ~ span, .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .selected, .messages-default-page .desktop-page-container .conversation-messages-wrapper .conversation-messages-header h3, .manage-edit-user-profile-component label, .manage-edit-user-profile-component .error-message, .manage-edit-roadmap-page .stage-item > div:first-child > svg, .manage-desktop-inline-adder button:last-child {
  margin-left: 0.5rem; }

.mrm-m-0_75, .msg-list .msg-list-row {
  margin: 0.75rem; }

.mrm-mx-0_75 {
  margin-left: 0.75rem;
  margin-right: 0.75rem; }

.mrm-my-0_75 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem; }

.mrm-mt-0_75, .user-components-profile-view .coach-card, .roadmap-action-item-page #formNotes, .roadmap-action-item-page button, .chat-list-row:nth-of-type(1) {
  margin-top: 0.75rem; }

.mrm-mr-0_75, .common-desktop-header .row .col.right-menu .menu-item.dropdown .dropdown-menu svg, .common-desktop-header .row .col.right-menu .menu-item.dropdown .dropdown-menu img, .roadmap-default-page .action-items, .action-item-page-attachment-list .attachment .file-name, .action-item-page-attachment-list .attachment .file-type, .new-message-modal .user-row .initials, .msg-list .msg-list-row .common-user-avatar {
  margin-right: 0.75rem; }

.mrm-mb-0_75, .roadmap-default-page h2, .roadmap-default-page .card, .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-slider-container p, .manage-edit-roadmap-page .stage-competency {
  margin-bottom: 0.75rem; }

.mrm-ml-0_75, .user-components-profile-form form .form-label, .action-item .ai-status {
  margin-left: 0.75rem; }

.mrm-m-1, .photo-edit-body .scale-slider {
  margin: 1rem; }

.mrm-mx-1, .messages-default-page .desktop-page-container .common-search-bar, .new-message-modal #groupName {
  margin-left: 1rem;
  margin-right: 1rem; }

.mrm-my-1, .roadmap-competency-page h2 {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.mrm-mt-1, .dashboard-admin-page .student-card .last-login, .dashboard-roadmap-card .started-date, .msg-list .msg-list-end-anchor, .manage-edit-user-roadmap-component .roadmap-title {
  margin-top: 1rem; }

.mrm-mr-1, .home-forgot-password-page .back-button-container button svg, .dashboard-admin-page .common-search-bar {
  margin-right: 1rem; }

.mrm-mb-1, .photo-edit-body .edit-actions, .user-components-profile-form hr, .dashboard-admin-page .student-card, .roadmap-competency-page .competency-comment, .roadmap-components-competency-page-action-items .action-item-container, .roadmap-components-competency-page-competency-learn-tab .notes-container .notes-description a, .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container, .action-item-page-attachment-list .attachment, .messages-default-page .desktop-page-container .conversations-list-wrapper .container, .messages-default-page .desktop-page-container .common-search-bar {
  margin-bottom: 1rem; }

.mrm-ml-1, .common-profile-view .profile-card .card-in-card > span, .roadmap-components-competency-page-competency-learn-tab .questions-section .question-item .answer {
  margin-left: 1rem; }

.mrm-m-1_5 {
  margin: 1.5rem; }

.mrm-mx-1_5 {
  margin-left: 1.5rem;
  margin-right: 1.5rem; }

.mrm-my-1_5, .common-profile-view .profile-card, .new-message-modal .selected-participants .participant {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }

.mrm-mt-1_5 {
  margin-top: 1.5rem; }

.mrm-mr-1_5, .common-desktop-header .row .col.left-menu img {
  margin-right: 1.5rem; }

.mrm-mb-1_5, .profile-card-item, .notifications-components-default-page-notifications-list .no-notifications .fa-bell {
  margin-bottom: 1.5rem; }

.mrm-ml-1_5, .common-desktop-header .row .col.right-menu .menu-item {
  margin-left: 1.5rem; }

.mrm-m-2 {
  margin: 2rem; }

.mrm-mx-2 {
  margin-left: 2rem;
  margin-right: 2rem; }

.mrm-my-2, .manage-user-page .profile-card {
  margin-top: 2rem;
  margin-bottom: 2rem; }

.mrm-mt-2, .messages-default-page .desktop-page-container, .notifications-components-default-page-notifications-list .no-notifications {
  margin-top: 2rem; }

.mrm-mr-2 {
  margin-right: 2rem; }

.mrm-mb-2, .dashboard-admin-page .desktop-page-container .content-wrapper > div:first-child, .roadmap-competency-page .comment-list, .manage-edit-user-profile-component, .manage-edit-roadmap-details-page {
  margin-bottom: 2rem; }

.mrm-ml-2 {
  margin-left: 2rem; }

.mrm-m-2_5 {
  margin: 2.5rem; }

.mrm-mx-2_5 {
  margin-left: 2.5rem;
  margin-right: 2.5rem; }

.mrm-my-2_5 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }

.mrm-mt-2_5 {
  margin-top: 2.5rem; }

.mrm-mr-2_5 {
  margin-right: 2.5rem; }

.mrm-mb-2_5, .roadmap-default-page .stage {
  margin-bottom: 2.5rem; }

.mrm-ml-2_5 {
  margin-left: 2.5rem; }

.mrm-m-3 {
  margin: 3rem; }

.mrm-mx-3 {
  margin-left: 3rem;
  margin-right: 3rem; }

.mrm-my-3 {
  margin-top: 3rem;
  margin-bottom: 3rem; }

.mrm-mt-3, .common-profile-view .desktop-profile-header .profile-card:first-child, .dashboard-admin-page .desktop-page-container .content-wrapper > div:first-child, .no-messages {
  margin-top: 3rem; }

.mrm-mr-3 {
  margin-right: 3rem; }

.mrm-mb-3, .home-forgot-password-page .back-button-container, .roadmap-default-page > .container {
  margin-bottom: 3rem; }

.mrm-ml-3, .msg-list .msg-list-row .msg-detail {
  margin-left: 3rem; }

.mrm-m-4 {
  margin: 4rem; }

.mrm-mx-4 {
  margin-left: 4rem;
  margin-right: 4rem; }

.mrm-my-4 {
  margin-top: 4rem;
  margin-bottom: 4rem; }

.mrm-mt-4 {
  margin-top: 4rem; }

.mrm-mr-4 {
  margin-right: 4rem; }

.mrm-mb-4, .roadmap-components-competency-page-competency-comments-tab .comments-anchor {
  margin-bottom: 4rem; }

.mrm-ml-4 {
  margin-left: 4rem; }

.mrm-m-6 {
  margin: 6rem; }

.mrm-mx-6 {
  margin-left: 6rem;
  margin-right: 6rem; }

.mrm-my-6 {
  margin-top: 6rem;
  margin-bottom: 6rem; }

.mrm-mt-6, .home-log-in-page .desktop-form-container, .home-sign-up-page .desktop-form-container, .manage-edit-user-profile-modal .modal-body {
  margin-top: 6rem; }

.mrm-mr-6 {
  margin-right: 6rem; }

.mrm-mb-6 {
  margin-bottom: 6rem; }

.mrm-ml-6 {
  margin-left: 6rem; }

.mrm-m-8 {
  margin: 8rem; }

.mrm-mx-8 {
  margin-left: 8rem;
  margin-right: 8rem; }

.mrm-my-8 {
  margin-top: 8rem;
  margin-bottom: 8rem; }

.mrm-mt-8 {
  margin-top: 8rem; }

.mrm-mr-8 {
  margin-right: 8rem; }

.mrm-mb-8 {
  margin-bottom: 8rem; }

.mrm-ml-8 {
  margin-left: 8rem; }

.mrm-m-12 {
  margin: 12rem; }

.mrm-mx-12 {
  margin-left: 12rem;
  margin-right: 12rem; }

.mrm-my-12 {
  margin-top: 12rem;
  margin-bottom: 12rem; }

.mrm-mt-12 {
  margin-top: 12rem; }

.mrm-mr-12 {
  margin-right: 12rem; }

.mrm-mb-12 {
  margin-bottom: 12rem; }

.mrm-ml-12 {
  margin-left: 12rem; }

.mrm-m-16 {
  margin: 16rem; }

.mrm-mx-16 {
  margin-left: 16rem;
  margin-right: 16rem; }

.mrm-my-16 {
  margin-top: 16rem;
  margin-bottom: 16rem; }

.mrm-mt-16 {
  margin-top: 16rem; }

.mrm-mr-16 {
  margin-right: 16rem; }

.mrm-mb-16 {
  margin-bottom: 16rem; }

.mrm-ml-16 {
  margin-left: 16rem; }

.mrm-m-24 {
  margin: 24rem; }

.mrm-mx-24 {
  margin-left: 24rem;
  margin-right: 24rem; }

.mrm-my-24 {
  margin-top: 24rem;
  margin-bottom: 24rem; }

.mrm-mt-24 {
  margin-top: 24rem; }

.mrm-mr-24 {
  margin-right: 24rem; }

.mrm-mb-24 {
  margin-bottom: 24rem; }

.mrm-ml-24 {
  margin-left: 24rem; }

.mrm-m-32 {
  margin: 32rem; }

.mrm-mx-32 {
  margin-left: 32rem;
  margin-right: 32rem; }

.mrm-my-32 {
  margin-top: 32rem;
  margin-bottom: 32rem; }

.mrm-mt-32 {
  margin-top: 32rem; }

.mrm-mr-32 {
  margin-right: 32rem; }

.mrm-mb-32 {
  margin-bottom: 32rem; }

.mrm-ml-32 {
  margin-left: 32rem; }

.mrm-m-40 {
  margin: 40rem; }

.mrm-mx-40 {
  margin-left: 40rem;
  margin-right: 40rem; }

.mrm-my-40 {
  margin-top: 40rem;
  margin-bottom: 40rem; }

.mrm-mt-40 {
  margin-top: 40rem; }

.mrm-mr-40 {
  margin-right: 40rem; }

.mrm-mb-40 {
  margin-bottom: 40rem; }

.mrm-ml-40 {
  margin-left: 40rem; }

.mrm-m-48 {
  margin: 48rem; }

.mrm-mx-48 {
  margin-left: 48rem;
  margin-right: 48rem; }

.mrm-my-48 {
  margin-top: 48rem;
  margin-bottom: 48rem; }

.mrm-mt-48 {
  margin-top: 48rem; }

.mrm-mr-48 {
  margin-right: 48rem; }

.mrm-mb-48 {
  margin-bottom: 48rem; }

.mrm-ml-48 {
  margin-left: 48rem; }

.mrm-p-0_25, table.common-custom-table tbody tr td, .action-item-page-attachment-list .attachment .delete, .roadmap-edit-assigned-coaches-page .default-roadmap-thumbnail, .manage-roadmaps-page .status-active {
  padding: 0.25rem; }

.mrm-px-0_25, table.common-custom-table thead th {
  padding-left: 0.25rem;
  padding-right: 0.25rem; }

.mrm-py-0_25, .common-desktop-back-button, .item-count {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem; }

.mrm-pt-0_25, .user-user-page .header-down-menu {
  padding-top: 0.25rem; }

.mrm-pr-0_25, .delete-comment-message-icon {
  padding-right: 0.25rem; }

.mrm-pb-0_25 {
  padding-bottom: 0.25rem; }

.mrm-pl-0_25 {
  padding-left: 0.25rem; }

.mrm-p-0_5, .common-profile-view .profile-card .card-in-card, .roadmap-default-page .default-stage-thumbnail, .manage-edit-user-roadmap-component .roadmap-title, .manage-competency-page .card.item, .manage-competency-page .card .assessment-input {
  padding: 0.5rem; }

.mrm-px-0_5, .common-desktop-back-button, .item-count, .roadmap-default-page .hidden-student {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

.mrm-py-0_5, .desktop-form-container, table.common-custom-table thead th, .dashboard-dashboard-page .dashboard-tab-selector .nav, .manage-edit-roadmap-page .stage-competency {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.mrm-pt-0_5, .notifications-default-page .container {
  padding-top: 0.5rem; }

.mrm-pr-0_5 {
  padding-right: 0.5rem; }

.mrm-pb-0_5, .roadmap-components-competency-page-competency-comments-tab .desktop-page-container .comment-form {
  padding-bottom: 0.5rem; }

.mrm-pl-0_5, .delete-comment-message-icon {
  padding-left: 0.5rem; }

.mrm-p-0_75, .photo-change-body a, .roadmap-competency-page .competency-comment .card, .roadmap-components-competency-page-competency-learn-tab .notes-container .notes-description a, .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-slider-container, .msg-list .msg-list-row .msg-detail .msg-text {
  padding: 0.75rem; }

.mrm-px-0_75, .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .slider-container, .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .assessment .card-body, .roadmap-components-competency-page-competency-learn-tab .questions-section .question-item, .manage-edit-roadmap-page .stage-competency {
  padding-left: 0.75rem;
  padding-right: 0.75rem; }

.mrm-py-0_75, .action-item, .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .assessment .card-body, .roadmap-components-competency-page-competency-learn-tab .questions-section .question-item, .action-item-page-attachment-list .attachment, .chat-list-row, .manage-competency-page .intro-content .card--embed, .manage-competency-page .coach-notes .card--embed {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem; }

.mrm-pt-0_75 {
  padding-top: 0.75rem; }

.mrm-pr-0_75, .action-item-page-attachment-list .attachment, .manage-competency-page .intro-content .card--embed, .manage-competency-page .coach-notes .card--embed {
  padding-right: 0.75rem; }

.mrm-pb-0_75, .roadmap-competency-page .desktop-header-container .tab-selector .nav-link {
  padding-bottom: 0.75rem; }

.mrm-pl-0_75, .dashboard-roadmap-library-page .card-body, .action-item-page-attachment-list .attachment, .manage-competency-page .intro-content .card--embed, .manage-competency-page .coach-notes .card--embed {
  padding-left: 0.75rem; }

.mrm-p-1, .roadmap-competency-page .comment-list .comment-form, .desktop-page-container .add-another-action-item, .msg-room .msg-form, .manage-competency-page .card {
  padding: 1rem; }

.mrm-px-1, .common-header .back-link, .common-profile-view .edit-profile-btn, .dashboard-roadmaps-page, .dashboard-roadmaps-page .card-body, .dashboard-dashboard-page .dashboard-tab-selector, .dashboard-admin-page .mobile-page-container, .new-message-modal .user-row {
  padding-left: 1rem;
  padding-right: 1rem; }

.mrm-py-1, .common-profile-view .profile-card, .add-note, .roadmap-action-item-page .ai-description, .new-message-modal .user-row, .notifications-components-default-page-notifications-list .notification-row {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.mrm-pt-1, .common-header .border-bottom-header, .user-user-page .container, .user-components-profile-view .mobile-view-container .container, .roadmap-competency-page .desktop-header-container, .messages-default-page .mobile-page-wrapper .border-bottom-header, .messages-default-page .desktop-page-container .conversations-list-wrapper .conversations-list-header, .messages-default-page .desktop-page-container .conversation-messages-wrapper .conversation-messages-header {
  padding-top: 1rem; }

.mrm-pr-1 {
  padding-right: 1rem; }

.mrm-pb-1, .dashboard-admin-page.sort-menu form, .dashboard-admin-page .mobile-page-container {
  padding-bottom: 1rem; }

.mrm-pl-1, .messages-default-page .desktop-page-container .conversations-list-wrapper .conversations-list-header, .messages-default-page .desktop-page-container .chat-list-row, .messages-default-page .desktop-page-container .conversation-messages-wrapper .conversation-messages-header {
  padding-left: 1rem; }

.mrm-p-1_5 {
  padding: 1.5rem; }

.mrm-px-1_5, .common-desktop-header .row .col.right-menu .notifications-dropdown-menu .notifications-list-wrapper, .roadmap-components-competency-page-competency-learn-tab .self-reflection .btn-bottom-left-rounded, .roadmap-components-competency-page-competency-learn-tab .self-reflection .btn-bottom-right-rounded {
  padding-left: 1.5rem;
  padding-right: 1.5rem; }

.mrm-py-1_5 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }

.mrm-pt-1_5, .roadmap-default-page > .container {
  padding-top: 1.5rem; }

.mrm-pr-1_5 {
  padding-right: 1.5rem; }

.mrm-pb-1_5, .common-desktop-header .row .col.right-menu .notifications-dropdown-menu .notifications-list-wrapper, .account-delete-modal .btn-cancel, .roadmap-components-competency-page-competency-comments-tab .desktop-page-container .comment-form {
  padding-bottom: 1.5rem; }

.mrm-pl-1_5 {
  padding-left: 1.5rem; }

.mrm-p-2, .entry {
  padding: 2rem; }

.mrm-px-2, .desktop-form-container, .dashboard-admin-page .form-check {
  padding-left: 2rem;
  padding-right: 2rem; }

.mrm-py-2, .dashboard-roadmaps-page {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.mrm-pt-2 {
  padding-top: 2rem; }

.mrm-pr-2 {
  padding-right: 2rem; }

.mrm-pb-2, .msg-list, .manage-edit-user-roadmap-modal .modal-content {
  padding-bottom: 2rem; }

.mrm-pl-2, .user-components-invite-coach-modal .form-group input {
  padding-left: 2rem; }

.mrm-p-2_5 {
  padding: 2.5rem; }

.mrm-px-2_5 {
  padding-left: 2.5rem;
  padding-right: 2.5rem; }

.mrm-py-2_5 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem; }

.mrm-pt-2_5 {
  padding-top: 2.5rem; }

.mrm-pr-2_5 {
  padding-right: 2.5rem; }

.mrm-pb-2_5 {
  padding-bottom: 2.5rem; }

.mrm-pl-2_5, .home-log-in-page .form-control, .home-sign-up-page .form-control, .home-forgot-password-page .form-control {
  padding-left: 2.5rem; }

.mrm-p-3, .common-network-detector {
  padding: 3rem; }

.mrm-px-3 {
  padding-left: 3rem;
  padding-right: 3rem; }

.mrm-py-3 {
  padding-top: 3rem;
  padding-bottom: 3rem; }

.mrm-pt-3, .home-forgot-password-page, .home-forgot-password-page .back-button-container {
  padding-top: 3rem; }

.mrm-pr-3 {
  padding-right: 3rem; }

.mrm-pb-3 {
  padding-bottom: 3rem; }

.mrm-pl-3 {
  padding-left: 3rem; }

.mrm-p-4 {
  padding: 4rem; }

.mrm-px-4 {
  padding-left: 4rem;
  padding-right: 4rem; }

.mrm-py-4 {
  padding-top: 4rem;
  padding-bottom: 4rem; }

.mrm-pt-4 {
  padding-top: 4rem; }

.mrm-pr-4 {
  padding-right: 4rem; }

.mrm-pb-4, .user-components-profile-view .mobile-view-container .container, .roadmap-action-item-page {
  padding-bottom: 4rem; }

.mrm-pl-4 {
  padding-left: 4rem; }

.mrm-p-6 {
  padding: 6rem; }

.mrm-px-6 {
  padding-left: 6rem;
  padding-right: 6rem; }

.mrm-py-6 {
  padding-top: 6rem;
  padding-bottom: 6rem; }

.mrm-pt-6 {
  padding-top: 6rem; }

.mrm-pr-6 {
  padding-right: 6rem; }

.mrm-pb-6 {
  padding-bottom: 6rem; }

.mrm-pl-6 {
  padding-left: 6rem; }

.mrm-p-8 {
  padding: 8rem; }

.mrm-px-8 {
  padding-left: 8rem;
  padding-right: 8rem; }

.mrm-py-8 {
  padding-top: 8rem;
  padding-bottom: 8rem; }

.mrm-pt-8 {
  padding-top: 8rem; }

.mrm-pr-8 {
  padding-right: 8rem; }

.mrm-pb-8 {
  padding-bottom: 8rem; }

.mrm-pl-8 {
  padding-left: 8rem; }

.mrm-p-12 {
  padding: 12rem; }

.mrm-px-12 {
  padding-left: 12rem;
  padding-right: 12rem; }

.mrm-py-12 {
  padding-top: 12rem;
  padding-bottom: 12rem; }

.mrm-pt-12, .common-user-not-approved {
  padding-top: 12rem; }

.mrm-pr-12 {
  padding-right: 12rem; }

.mrm-pb-12 {
  padding-bottom: 12rem; }

.mrm-pl-12 {
  padding-left: 12rem; }

.mrm-p-16 {
  padding: 16rem; }

.mrm-px-16 {
  padding-left: 16rem;
  padding-right: 16rem; }

.mrm-py-16 {
  padding-top: 16rem;
  padding-bottom: 16rem; }

.mrm-pt-16 {
  padding-top: 16rem; }

.mrm-pr-16 {
  padding-right: 16rem; }

.mrm-pb-16 {
  padding-bottom: 16rem; }

.mrm-pl-16 {
  padding-left: 16rem; }

.mrm-p-24 {
  padding: 24rem; }

.mrm-px-24 {
  padding-left: 24rem;
  padding-right: 24rem; }

.mrm-py-24 {
  padding-top: 24rem;
  padding-bottom: 24rem; }

.mrm-pt-24 {
  padding-top: 24rem; }

.mrm-pr-24 {
  padding-right: 24rem; }

.mrm-pb-24 {
  padding-bottom: 24rem; }

.mrm-pl-24 {
  padding-left: 24rem; }

.mrm-p-32 {
  padding: 32rem; }

.mrm-px-32 {
  padding-left: 32rem;
  padding-right: 32rem; }

.mrm-py-32 {
  padding-top: 32rem;
  padding-bottom: 32rem; }

.mrm-pt-32 {
  padding-top: 32rem; }

.mrm-pr-32 {
  padding-right: 32rem; }

.mrm-pb-32 {
  padding-bottom: 32rem; }

.mrm-pl-32 {
  padding-left: 32rem; }

.mrm-p-40 {
  padding: 40rem; }

.mrm-px-40 {
  padding-left: 40rem;
  padding-right: 40rem; }

.mrm-py-40 {
  padding-top: 40rem;
  padding-bottom: 40rem; }

.mrm-pt-40 {
  padding-top: 40rem; }

.mrm-pr-40 {
  padding-right: 40rem; }

.mrm-pb-40 {
  padding-bottom: 40rem; }

.mrm-pl-40 {
  padding-left: 40rem; }

.mrm-p-48 {
  padding: 48rem; }

.mrm-px-48 {
  padding-left: 48rem;
  padding-right: 48rem; }

.mrm-py-48 {
  padding-top: 48rem;
  padding-bottom: 48rem; }

.mrm-pt-48 {
  padding-top: 48rem; }

.mrm-pr-48 {
  padding-right: 48rem; }

.mrm-pb-48 {
  padding-bottom: 48rem; }

.mrm-pl-48 {
  padding-left: 48rem; }

.normal-width-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1095px; }
  @media (max-width: 1199.98px) {
    .normal-width-container {
      max-width: 920px; } }

.mrm-box-shadow, .desktop-form-container, .photo-edit-body .rc-slider-handle, .roadmap-competency-page .desktop-header-container, .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .slider-container .rc-slider .rc-slider-handle, .messages-default-page .desktop-page-container {
  box-shadow: 0 2px 5px 0 #0002; }

.mrm-main-box-shadow, .desktop-page-secondary-header-wrapper, .btn-white, .btn-white:focus, .btn-white:active, .btn-white:hover, .card, table.common-custom-table, .common-profile-view .profile-card, .roadmap-components-competency-page-action-items .action-item-container, .roadmap-components-competency-page-competency-learn-tab .notes-container .notes-description a, .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container, .roadmap-components-competency-page-competency-learn-tab .questions-section, .manage-user-page .desktop-view-container .common-desktop-back-button, .manage-group-view-page .common-desktop-back-button {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05); }

.mrm-white-on-white-box-shadow, .common-profile-view .profile-card .card-in-card, .dashboard-roadmap-card, .action-item-page-attachment-list .attachment, .manage-edit-user-roadmap-component .card-in-card, .manage-group-view-page .card-in-card, .manage-competency-page .card.item, .manage-competency-page .card .assessment-input, .manage-competency-page .intro-content .card--embed, .manage-competency-page .coach-notes .card--embed {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.09) !important;
  border-radius: 10px; }

.mrm-white-on-white-vivid-box-shadow, .dropdown-menu {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
  border-radius: 10px; }

.mrm-border-bottom {
  box-shadow: 0 1px 0 #0001; }

.font-size-small {
  font-size: 0.875rem; }

.font-size-large {
  font-size: 1.125rem; }

.font-size-largest {
  font-size: 1.5rem; }

.font-size-smaller {
  font-size: 0.8rem; }

.font-size-smallest {
  font-size: 0.7rem; }

.bold {
  font-weight: 800; }

.semibold {
  font-weight: 600; }

.w-fit-content {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.home-app .toast-container {
  position: absolute;
  left: 50%;
  top: 20px; }
  .home-app .toast-container .toast {
    position: relative;
    left: -50%;
    z-index: 1; }

.home-app .stage-env-banner {
  position: fixed;
  z-index: 1024;
  right: 0;
  left: 0;
  font-size: 0.8rem;
  border-bottom: 1px solid #F5C6CA;
  text-align: center;
  color: #731C23;
  background-color: #F8D7D9; }

.welcome-page {
  height: 100vh; }
  .welcome-page h1 {
    color: white; }
  .welcome-page .btn {
    max-width: 350px;
    display: block;
    margin: auto; }

.light .welcome-page h1 {
  color: #3e3e3e; }

.blue-ocean .welcome-page {
  background-image: url(/static/media/beach-bg.7d61eb15.jpg);
  background-size: cover;
  background-position-x: 80%; }

.light .welcome-page h1 {
  color: #3A3A3B; }

.blue-ocean .welcome-page {
  background-image: url(/static/media/beach-bg.7d61eb15.jpg);
  background-size: cover;
  background-position-x: 80%; }

.welcome .desktop-welcome h2 {
  font-size: 1.25rem; }

.home-log-in-page {
  min-height: 100vh; }
  .home-log-in-page p {
    text-align: center; }
  .home-log-in-page .form-group .svg-inline--fa {
    position: absolute;
    color: #007bff; }
  .home-log-in-page .legal-info {
    color: #727272;
    font-size: 0.875rem; }

.home-sign-up-page {
  min-height: 100vh; }
  .home-sign-up-page .form-group .svg-inline--fa {
    position: absolute;
    color: #007bff; }
  .home-sign-up-page .legal-info {
    color: #727272;
    font-size: 0.875rem; }
    .home-sign-up-page .legal-info .legal-link {
      color: #727272; }
      .home-sign-up-page .legal-info .legal-link:hover {
        color: #727272; }

.home-forgot-password-page {
  min-height: 100vh; }
  .home-forgot-password-page .common-header {
    background-color: #ECF4FA; }
  .home-forgot-password-page .form-group .svg-inline--fa {
    position: absolute;
    color: #007bff; }
  .home-forgot-password-page .reset-instructions {
    color: #3e3e3e;
    font-size: 0.875rem; }
  .home-forgot-password-page .back-button-container button {
    color: #3e3e3e; }
    .home-forgot-password-page .back-button-container button svg {
      font-size: 1.125rem;
      vertical-align: middle; }

.home-reset-password-page {
  min-height: 100vh; }
  .home-reset-password-page .common-header {
    background-color: transparent; }
  .home-reset-password-page .avatar {
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 50%;
    background-color: #A768B6;
    color: white;
    text-align: center;
    padding-top: 5px;
    display: inline-block; }

.home-unsubscribe-page {
  min-height: 100vh; }

p.grey {
  color: #727272; }

h1 {
  font-weight: bold;
  font-size: 1.9rem;
  line-height: 41px; }

.desktop-form-container {
  background: white;
  border-radius: 0.5rem;
  min-width: 24rem;
  border-color: #cccccc; }
  .desktop-form-container .form-control {
    border: 1px solid #cccccc; }

.common-header-container .static-placeholder {
  visibility: hidden; }

.common-header-container .fixed-actual-header {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%; }

.common-header {
  padding: 20px 15px 0;
  overflow-wrap: break-word; }
  .common-header a, .common-header .back-link {
    color: #3e3e3e; }
  .common-header .back-link-container a:hover {
    color: #3e3e3e;
    opacity: 1; }
  .common-header .border-bottom-header {
    border-bottom: 1px solid #cccccc; }
  .common-header h1 {
    font-weight: bold;
    text-align: center;
    font-size: 1.125rem;
    line-height: 20px;
    margin-bottom: 0; }
  .common-header button {
    font-size: 1rem; }
  .common-header .full {
    margin: 0;
    width: 100vw;
    position: relative;
    left: -15px; }
  .common-header .row {
    align-items: center; }
  .common-header .back-link-container {
    padding-left: 0;
    padding-right: 0;
    text-align: center; }
    .common-header .back-link-container a {
      display: inline-block;
      min-width: 3rem; }
  .common-header .back-link {
    cursor: pointer; }

.dark .common-header .border-bottom-header {
  opacity: .2; }

.dark .common-header svg {
  color: white; }

.dark .common-header {
  background-color: #2d3842; }
  .dark .common-header .border-bottom-header {
    opacity: .2; }
  .dark .common-header svg {
    color: white; }

.common-bottom-nav .navbar {
  height: 4rem;
  border-top: 1px solid #cccccc; }
  .common-bottom-nav .navbar span.badge {
    color: white;
    position: absolute;
    top: 58%;
    transform: translate(12%, -150%);
    font-size: 0.5rem;
    line-height: 1.3; }
    .common-bottom-nav .navbar span.badge.transparent-badge {
      color: transparent; }
  .common-bottom-nav .navbar .nav-link {
    color: #cccccc; }
  .common-bottom-nav .navbar .nav-item {
    flex-basis: 0; }

.dark .common-bottom-nav .navbar {
  border-top: 1px solid #555; }

.common-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9; }
  .common-loader.screen-center {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0; }
  .common-loader.local-center {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0; }

.dark .common-loader .spinner-border {
  color: #8d8d8d; }

.common-network-detector {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh; }
  .common-network-detector .offline-description {
    text-align: center;
    color: #727272; }

.common-user-not-approved h2 {
  line-height: 1.5; }

.common-user-not-approved a {
  text-decoration: none;
  font-weight: normal; }

.common-user-avatar .profile-photo {
  display: flex;
  justify-content: center; }
  .common-user-avatar .profile-photo img {
    width: 3rem;
    height: 3rem;
    border-radius: 50%; }
  .common-user-avatar .profile-photo.xsmall img {
    width: 1.6rem;
    height: 1.6rem; }
  .common-user-avatar .profile-photo.small img {
    width: 2rem;
    height: 2rem; }
  .common-user-avatar .profile-photo.large img {
    width: 5rem;
    height: 5rem; }
  .common-user-avatar .profile-photo.xlarge img {
    width: 7rem;
    height: 7rem; }

.common-user-avatar .initials.small {
  width: 2rem;
  height: 2rem; }
  .common-user-avatar .initials.small span {
    font-size: 0.9rem;
    color: #ffffff; }

.common-user-avatar .initials.xsmall {
  width: 1.6rem;
  height: 1.6rem; }
  .common-user-avatar .initials.xsmall span {
    font-size: 0.7rem; }

.common-user-avatar .initials.large {
  width: 5rem;
  height: 5rem; }
  .common-user-avatar .initials.large span {
    font-size: 2rem;
    line-height: 2.75rem; }

.common-user-avatar .initials.xlarge {
  width: 7rem;
  height: 7rem; }
  .common-user-avatar .initials.xlarge span {
    font-size: 3.25rem; }

.tab-selector {
  position: relative;
  z-index: 1; }
  .tab-selector::after {
    content: ' ';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -3px;
    border-bottom: 3px solid #cccccc; }
  .tab-selector .nav-link {
    line-height: 1;
    border-radius: 0;
    text-decoration: none;
    font-size: 0.875rem;
    padding-left: 0;
    padding-right: 0; }
  .tab-selector .nav-link {
    color: #aaaaaa; }
    .tab-selector .nav-link.active {
      background-color: inherit; }
  .tab-selector .slider-container {
    position: relative; }
    @media (max-width: 991.98px) {
      .tab-selector .slider-container {
        top: 3px; } }
    .tab-selector .slider-container .slider {
      z-index: 1;
      position: absolute;
      height: 3px;
      background-color: #3E3E3E;
      bottom: 0;
      left: 0%;
      transition: left ease 0.2s;
      border-radius: 2px; }

.dark .tab-selector::after {
  border-color: #777; }

.dark .tab-selector .slider {
  background-color: white; }

.dark .tab-selector .nav-link {
  color: #8d8d8d; }

.common-custom-dialog .btn-cancel {
  color: #3e3e3e; }
  .common-custom-dialog .btn-cancel:focus {
    border: none; }

.common-action-menu .modal-dialog .modal-content {
  background-color: transparent;
  border: none; }
  .common-action-menu .modal-dialog .modal-content .btn-menu-item {
    color: #3e3e3e; }
  .common-action-menu .modal-dialog .modal-content a:hover {
    opacity: 1; }
  .common-action-menu .modal-dialog .modal-content .show-rename-form-desktop + .border-thin {
    display: none; }
  @media (min-width: 992px) {
    .common-action-menu .modal-dialog .modal-content .show-rename-form-desktop + .border-thin {
      display: block; }
      .common-action-menu .modal-dialog .modal-content .show-rename-form-desktop + .border-thin + a + .border-thin {
        display: none; } }

.modal-desktop-open-center .modal-dialog {
  width: 400px; }

.modal-desktop-open-center .top-body {
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.modal-desktop-open-center .cancel {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 1px solid #ddd; }

.modal-backdrop.show {
  opacity: 0.25; }

.common-search-bar {
  position: relative; }
  .common-search-bar .form-control:focus {
    border: 1px solid #EAEEF5; }
  .common-search-bar .search-icon {
    position: absolute;
    left: 12px;
    top: 12px;
    font-size: 0.875rem;
    color: #727272; }

table.common-custom-table {
  border-radius: 10px;
  background-color: white;
  width: 100%; }
  table.common-custom-table thead th {
    border-bottom: 1px solid #eeeeee;
    font-size: 1rem;
    color: #727272;
    white-space: nowrap; }
    table.common-custom-table thead th svg {
      color: #727272; }
  table.common-custom-table thead th:not(:last-child) {
    border-right: none; }
  table.common-custom-table tbody tr {
    font-size: 0.875rem; }
    table.common-custom-table tbody tr td:first-child {
      color: #3e3e3e; }
    table.common-custom-table tbody tr td {
      color: #3e3e3e;
      vertical-align: middle;
      padding: 12px 5px; }
    table.common-custom-table tbody tr td:not(:last-child) {
      border-right: none; }
  table.common-custom-table tr:not(:last-child) {
    border-bottom: 1px solid #eeeeee; }

.common-desktop-header-container .static-placeholder {
  visibility: hidden; }

.common-desktop-header-container .fixed-actual-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000; }

.common-desktop-header {
  width: 100%; }
  .common-desktop-header .top-menu-container {
    background: white;
    border-bottom: 1px solid #f0f1f2; }
  .common-desktop-header .dropdown-item {
    margin-bottom: 5px; }
    .common-desktop-header .dropdown-item:active {
      background: none; }
    .common-desktop-header .dropdown-item svg {
      font-size: 1.125rem; }
  .common-desktop-header .badge {
    position: absolute;
    top: -4px;
    left: 12px;
    cursor: pointer; }
  .common-desktop-header .row {
    margin-left: 0;
    margin-right: 0;
    min-height: 2rem;
    align-items: center;
    justify-content: space-between; }
    .common-desktop-header .row .col {
      flex-basis: content; }
      .common-desktop-header .row .col.left-menu img {
        max-height: 4.375rem;
        border-radius: 10px;
        border: 1px solid #eeeeee;
        margin-top: 5px;
        margin-bottom: 5px;
        height: 4.375rem;
        width: 4.375rem;
        display: block; }
      .common-desktop-header .row .col.left-menu a {
        color: #aaaaaa; }
        .common-desktop-header .row .col.left-menu a:hover {
          color: #727272; }
      .common-desktop-header .row .col.left-menu a.active {
        color: #3e3e3e; }
        .common-desktop-header .row .col.left-menu a.active:hover {
          color: #3e3e3e;
          opacity: 1; }
      .common-desktop-header .row .col.left-menu .company-logo:hover {
        opacity: 1; }
      .common-desktop-header .row .col.right-menu a {
        color: #aaaaaa;
        font-size: 1rem; }
        .common-desktop-header .row .col.right-menu a:hover {
          color: #727272;
          opacity: 1; }
      .common-desktop-header .row .col.right-menu a.active svg {
        color: #3e3e3e; }
        .common-desktop-header .row .col.right-menu a.active svg:hover {
          color: #3e3e3e;
          opacity: 1; }
      .common-desktop-header .row .col.right-menu a.active {
        color: #3e3e3e; }
        .common-desktop-header .row .col.right-menu a.active:hover {
          color: #3e3e3e;
          opacity: 1; }
      .common-desktop-header .row .col.right-menu .dropdown-item {
        color: #3e3e3e; }
      .common-desktop-header .row .col.right-menu .menu-item svg {
        color: #8d8d8d;
        vertical-align: middle; }
      .common-desktop-header .row .col.right-menu .menu-item:first-child {
        margin-left: 0; }
      .common-desktop-header .row .col.right-menu .menu-item.dropdown {
        display: inline-block;
        vertical-align: middle; }
        .common-desktop-header .row .col.right-menu .menu-item.dropdown .menu-item {
          vertical-align: text-top; }
          .common-desktop-header .row .col.right-menu .menu-item.dropdown .menu-item:hover {
            opacity: 1;
            color: #aaaaaa; }
          .common-desktop-header .row .col.right-menu .menu-item.dropdown .menu-item svg {
            color: #aaaaaa;
            margin-left: 2px;
            position: relative;
            bottom: 1px; }
            .common-desktop-header .row .col.right-menu .menu-item.dropdown .menu-item svg:hover {
              opacity: 1;
              color: #aaaaaa; }
        .common-desktop-header .row .col.right-menu .menu-item.dropdown .dropdown-menu svg {
          width: 1rem;
          vertical-align: middle;
          font-size: 1.125rem;
          color: #3e3e3e; }
        .common-desktop-header .row .col.right-menu .menu-item.dropdown .dropdown-menu img {
          font-size: 1.125rem; }
      .common-desktop-header .row .col.right-menu .profile-photo, .common-desktop-header .row .col.right-menu .initials {
        border: 1px solid transparent;
        padding: 1px; }
      .common-desktop-header .row .col.right-menu .show svg {
        color: #3e3e3e; }
      .common-desktop-header .row .col.right-menu .show .profile-photo, .common-desktop-header .row .col.right-menu .show .initials {
        border: 1px solid #3e3e3e;
        border-radius: 50%; }
      .common-desktop-header .row .col.right-menu .show .notification-row .initials {
        border: none; }
      .common-desktop-header .row .col.right-menu .menu-item-container {
        cursor: pointer; }
        .common-desktop-header .row .col.right-menu .menu-item-container .btn {
          font-size: 1rem;
          color: #3e3e3e; }
          .common-desktop-header .row .col.right-menu .menu-item-container .btn svg {
            color: #3e3e3e; }
      .common-desktop-header .row .col.right-menu .notifications-dropdown-menu {
        border-radius: 10px; }
        .common-desktop-header .row .col.right-menu .notifications-dropdown-menu .notifications-list-wrapper {
          min-width: 24rem;
          max-height: calc(100vh - 200px);
          overflow-y: auto; }
        .common-desktop-header .row .col.right-menu .notifications-dropdown-menu .notifications-list-header h2 {
          line-height: 2;
          margin-bottom: 0; }
        .common-desktop-header .row .col.right-menu .notifications-dropdown-menu .notification-row {
          flex-wrap: nowrap; }
          .common-desktop-header .row .col.right-menu .notifications-dropdown-menu .notification-row .initials {
            flex-shrink: 0; }

.desktop-page-secondary-header-wrapper h1 {
  font-size: 1.25rem;
  line-height: normal;
  line-height: initial; }

.profile-dropdown-menu {
  transform: translate3d(-150px, 44px, 0px) !important;
  min-width: 200px; }

.notifications-dropdown-menu {
  transform: translate3d(-306px, 40px, 0px) !important; }

.admin-dropdown-menu {
  transform: translate3d(-104px, 40px, 0px) !important; }

.common-switch-active-company-modal img, .common-switch-active-company-modal span {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #eeeeee; }

.common-switch-active-company-modal span {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #aaaaaa;
  color: white;
  font-size: 1.25rem; }

.common-switch-active-company-modal h1 {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0;
  line-height: 60px;
  display: inline-block; }

.common-switch-active-company-modal .modal-dialog {
  min-height: 100%; }
  @media (max-width: 575.98px) {
    .common-switch-active-company-modal .modal-dialog {
      margin: 0; } }

@media (max-width: 575.98px) {
  .common-switch-active-company-modal .modal-content {
    border: 0;
    border-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; } }

.common-switch-active-company-modal .company-item:hover {
  cursor: pointer;
  background: #f7f7f7; }

.common-switch-active-company-modal .company-item.active:hover {
  cursor: default;
  background: transparent; }

.common-switch-active-company-modal .company-item.active h1:hover {
  cursor: text; }

.common-switch-active-company-modal .company-item:not(:last-child) {
  border-bottom: 1px solid lightgrey; }

.common-desktop-back-button {
  display: inline-block;
  height: 40px;
  width: 40px;
  cursor: pointer;
  color: #727272;
  background: #F4F4F5;
  border-radius: 50%; }
  .common-desktop-back-button svg {
    font-size: 1.25rem;
    position: relative;
    top: 5px;
    left: 3px; }
  .common-desktop-back-button:hover {
    cursor: pointer;
    background-color: #efeff0; }

.user-profile-page {
  min-height: 100vh; }
  .user-profile-page button.btn-link {
    text-decoration: none;
    outline: none;
    background: transparent; }
    .user-profile-page button.btn-link:focus {
      box-shadow: none; }
  .user-profile-page .common-header button {
    color: #727272;
    font-weight: bold; }
    .user-profile-page .common-header button[type=submit] {
      color: #2f80ed;
      font-weight: bold; }
  .user-profile-page .more-btn-desktop {
    position: absolute;
    right: 0.75rem;
    top: 0.75rem; }

.photo-edit-body .rc-slider-rail {
  height: 6px; }

.photo-edit-body .rc-slider-track {
  background: #2f80ed;
  height: 6px; }

.photo-edit-body .rc-slider-handle {
  width: 30px;
  height: 30px;
  margin-top: -13px;
  border-radius: 10px;
  border: solid 2px #2f80ed; }

.photo-edit-body .edit-actions {
  display: flex;
  align-items: center;
  justify-content: space-around; }

.photo-modal-title {
  display: flex;
  justify-content: center; }

.photo-change-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0; }
  .photo-change-body a {
    color: #3e3e3e;
    font-weight: 400;
    height: 50px; }
  .photo-change-body a.remove {
    color: #ff3b30; }
  .photo-change-body button {
    width: 100%; }

.dark .user-profile-page hr {
  border-color: #555; }

.profile-card-item p {
  margin-bottom: -4px;
  color: #727272;
  font-size: 0.875rem; }
  .profile-card-item p:first-child {
    color: #3e3e3e;
    font-weight: 400;
    font-size: 1rem; }
  .profile-card-item p.student-link {
    color: #2f80ed;
    text-decoration: underline; }

.profile-card-item svg.field {
  color: #3e3e3e;
  background: #eeeeee;
  border-radius: 50%;
  width: 2.375rem;
  height: 2.375rem;
  padding: .55rem;
  display: block;
  margin: auto; }

.user-components-profile-form hr {
  width: calc(100% + 2rem);
  height: 1px;
  margin-left: -1rem; }

.user-components-profile-form form .form-group {
  margin-bottom: 1.3rem; }

@media (min-width: 576px) {
  .user-components-profile-form .form-container {
    border-radius: 10px; } }

@media (max-width: 575.98px) {
  .user-components-profile-form .form-container {
    background-color: transparent; }
    .user-components-profile-form .form-container .btn-delete-account {
      background-color: white; } }

@media (min-width: 576px) and (max-width: 767.98px) {
  .user-components-profile-form .profile-photo {
    margin-top: 1rem; } }

.user-components-profile-form .desktop-save-cancel .btn {
  flex-grow: 1; }

.profile-edit-form-container {
  min-width: 350px; }

.account-delete-modal .confirm {
  max-width: 20em; }

@media (max-width: 575.98px) {
  .account-delete-modal h1.title {
    font-size: 1rem;
    margin: auto;
    line-height: 22px; } }

.account-delete-modal .btn-cancel {
  height: 30px; }

.account-delete-modal .btn-delete-account {
  height: auto; }

.account-delete-modal .input-sm {
  height: 30px;
  font-size: 0.8rem; }

@media (min-width: 576px) {
  .user-components-profile-form .form-control {
    border: 1px solid #cccccc; }
    .user-components-profile-form .form-control:focus {
      border: 1px solid #2f80ed; }
  .user-components-profile-form .form-container {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
    margin-bottom: 2.5rem; } }

@media (min-width: 576px) and (max-width: 767.98px) {
  .user-components-user-avatar-editor {
    padding-top: 1.5rem; } }

@media (max-width: 575.98px) {
  .account-change-password-modal h1.title {
    font-size: 1rem;
    margin: auto;
    line-height: 22px; } }

.account-change-password-modal .form-control {
  border-color: #cccccc; }

.account-change-password-modal .cancel-btn {
  position: absolute;
  left: 0;
  top: 0;
  font-weight: bold; }

.account-change-password-modal .input-sm {
  height: 30px;
  font-size: 0.8rem; }

.account-change-password-modal .btn-primary:disabled {
  border-color: #aaaaaa;
  background-color: #aaaaaa; }

.user-components-user-avatar-editor .upload-photo {
  text-align: center; }
  .user-components-user-avatar-editor .upload-photo button {
    font-size: 0.875rem;
    color: #2f80ed; }

.photo-edit-body .edit-actions {
  display: flex;
  align-items: center;
  justify-content: space-around; }

.user-user-page {
  min-height: 50vh; }
  .user-user-page a {
    display: inherit;
    text-decoration: none;
    font-weight: 400; }
  .user-user-page .user-name {
    margin-left: -5px; }
  .user-user-page hr {
    border-color: #e3e3e3;
    margin-left: -15px;
    margin-right: -15px; }
  .user-user-page .user-page-header p:first-child {
    font-weight: bold;
    margin-bottom: -5px;
    font-size: 1rem; }
  .user-user-page .user-page-header p:last-child a {
    text-decoration: none;
    font-weight: 400;
    font-size: 0.875rem; }
  .user-user-page .user-page-header hr {
    width: 90%; }
  .user-user-page .user-page-item {
    cursor: pointer; }
    .user-user-page .user-page-item svg {
      font-size: 1.125rem;
      width: 1.5rem; }

.dark .user-user-page hr {
  border-color: #555; }

.user-components-invite-coach-modal .modal-dialog {
  border: 0; }

.user-components-invite-coach-modal .modal-content {
  border: 0; }

.user-components-invite-coach-modal button {
  height: inherit; }

.user-components-invite-coach-modal .form-group {
  position: relative; }
  .user-components-invite-coach-modal .form-group svg {
    color: #727272;
    position: absolute;
    left: 0.5rem;
    top: 0.75rem;
    font-size: 1rem; }

.user-components-invite-coach-modal.mobile-modal .modal-dialog {
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0; }

.user-components-invite-coach-modal.mobile-modal .modal-content {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.user-notifications-settings-page .container {
  margin-top: 1rem; }

.user-notifications-settings-page .notification-settings-page-row div:first-child {
  text-align: center; }

.user-delivery-type-notifications-settings-page .notification-type-switch {
  padding: 15px; }
  .user-delivery-type-notifications-settings-page .notification-type-switch div:last-child {
    display: flex;
    justify-content: center;
    align-items: center; }

.user-delivery-type-notifications-settings-page .button-container {
  padding: 15px; }
  .user-delivery-type-notifications-settings-page .button-container button {
    background-color: #f4f4f5; }

.common-profile-view .profile-card {
  background-color: white;
  border-width: 0;
  border-radius: 10px; }
  .common-profile-view .profile-card .wrap {
    overflow-wrap: break-word; }
  .common-profile-view .profile-card .card-in-card {
    background-color: #fff;
    font-weight: bold;
    min-height: 4rem;
    display: flex;
    align-items: center; }
  .common-profile-view .profile-card .profile-card-header p {
    margin-bottom: 0; }
    .common-profile-view .profile-card .profile-card-header p.font-italic {
      font-size: 0.875rem;
      color: #727272; }
    .common-profile-view .profile-card .profile-card-header p:first-child {
      font-size: 1.125rem;
      color: #3e3e3e; }
  .common-profile-view .profile-card .header {
    font-size: 1.125rem;
    color: #3e3e3e; }

.common-profile-view .edit-profile-btn {
  width: auto;
  border-width: 0;
  position: relative;
  top: -4px;
  right: -2px; }

.common-profile-view .desktop-profile-header .avatar {
  position: absolute;
  left: 2rem; }

.common-profile-view .desktop-profile-header .full-name {
  padding-top: 0.5rem; }

.common-profile-view .desktop-profile-header .user-bio {
  min-height: 1.5rem; }

.common-profile-view .desktop-profile-header .profile-card:first-child {
  min-height: 8rem; }

.common-profile-view .desktop-profile-header .profile-card-item > div:first-child {
  text-align: center; }

.common-profile-view .desktop-view-container .users-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .common-profile-view .desktop-view-container .users-cards-container > div {
    flex-basis: 49.2%; }

.user-components-profile-view .coach-card {
  position: relative; }

.user-components-profile-view hr {
  margin-top: 1.3rem;
  margin-bottom: 1.3rem;
  background: #e8e8e8; }

.user-components-profile-view .desktop-view-container .invite-coach {
  position: absolute;
  top: 0.75rem;
  right: 1rem; }

.user-components-profile-view .desktop-view-container .users-cards-container .dropdown .more-btn-desktop {
  top: unset;
  bottom: 0.5rem; }

.user-components-profile-view .mobile-view-container .avatar {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -60%); }

.user-components-profile-view .mobile-view-container .invite-coach {
  position: absolute;
  top: -11px;
  right: 0; }

.dashboard-roadmaps-page {
  min-height: 50vh; }
  @media (max-width: 991.98px) {
    .dashboard-roadmaps-page {
      padding-top: .5rem; } }
  .dashboard-roadmaps-page .default-roadmap-thumbnail {
    background-color: #D0E0FF;
    border-radius: 10px;
    height: 70px;
    width: 70px;
    color: white; }
  .dashboard-roadmaps-page .start-roadmap {
    color: #5F9CEF;
    font-size: 0.875rem;
    font-weight: bold;
    display: block;
    margin-top: -7px; }
  .dashboard-roadmaps-page .progress-text {
    color: #727272;
    font-size: 0.8rem; }
  .dashboard-roadmaps-page .card-img {
    border-radius: 10px;
    width: 70px;
    height: auto; }
  .dashboard-roadmaps-page .card-title {
    font-size: 1rem;
    font-weight: bold; }
  .dashboard-roadmaps-page .card span.small {
    color: #727272; }
  .dashboard-roadmaps-page .progress {
    height: 4px;
    border-radius: 2px;
    display: flex;
    overflow: hidden;
    background-color: #DEDEDE; }
  .dashboard-roadmaps-page .progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    background-color: #5F9CEF;
    transition: width 0.6s ease;
    border-radius: 4px; }
  .dashboard-roadmaps-page .assigned-coaches {
    display: flex;
    flex-direction: row; }

.dashboard-roadmap-library-page .card-img {
  background-color: #edc342;
  border-radius: 10px;
  height: 70px;
  width: 70px;
  color: white; }

.dashboard-roadmap-library-page .card-title {
  font-size: 1rem;
  font-weight: bold; }

.dashboard-roadmap-library-page .card-subtitle {
  color: #727272;
  font-size: 0.875rem; }

.dashboard-roadmap-library-page .card span.small {
  color: #727272; }

.dashboard-roadmap-library-page .assigned-label {
  color: #727272;
  float: right; }

.dashboard-dashboard-page {
  min-height: 100vh; }
  .dashboard-dashboard-page .tab-selector-container {
    height: 4em; }
  .dashboard-dashboard-page .dashboard-tab-selector {
    position: fixed;
    left: 0;
    right: 0; }
    .dashboard-dashboard-page .dashboard-tab-selector .nav-link {
      font-size: 0.875rem; }
      .dashboard-dashboard-page .dashboard-tab-selector .nav-link:hover {
        color: #000;
        color: initial; }

.blue-ocean .dashboard-dashboard-page {
  background-image: url(/static/media/beach-bg.7d61eb15.jpg);
  background-position-x: 80%;
  background-size: cover; }

.dashboard-admin-page .common-search-bar {
  flex-grow: 1; }
  .dashboard-admin-page .common-search-bar .search {
    height: 40px;
    background: #F1F2F3;
    padding-left: 35px;
    -webkit-appearance: none;
    border: none; }
    @media (max-width: 991.98px) {
      .dashboard-admin-page .common-search-bar .search {
        background: #e1e6eb; } }

.dashboard-admin-page .sort {
  background: #F1F2F3;
  box-shadow: none; }
  @media (max-width: 991.98px) {
    .dashboard-admin-page .sort {
      background: #e1e6eb; } }
  .dashboard-admin-page .sort.sort-desktop {
    font-size: 1rem;
    font-weight: normal; }
    .dashboard-admin-page .sort.sort-desktop span, .dashboard-admin-page .sort.sort-desktop svg {
      color: #3e3e3e; }
    .dashboard-admin-page .sort.sort-desktop span {
      font-weight: bold; }
    .dashboard-admin-page .sort.sort-desktop svg {
      vertical-align: bottom; }

.dashboard-admin-page .no-data {
  font-size: 0.8rem;
  text-align: center;
  font-style: italic;
  width: 100%; }

.dashboard-admin-page.sort-menu .modal-dialog {
  margin: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  max-width: none; }

.dashboard-admin-page.sort-menu .modal-content {
  border: none;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.dashboard-admin-page.sort-menu .form-check-input {
  width: 20px;
  height: 20px;
  margin-top: .1rem;
  border: 1px solid #727272;
  border-radius: 50%;
  -webkit-appearance: none; }
  .dashboard-admin-page.sort-menu .form-check-input:focus {
    outline: none; }
  .dashboard-admin-page.sort-menu .form-check-input:checked {
    border: none; }
    .dashboard-admin-page.sort-menu .form-check-input:checked:before {
      content: '\25C9';
      color: #2f80ed;
      font-size: 22px;
      position: relative;
      top: -7px;
      left: -1px; }

.dashboard-admin-page.sort-menu .cancel {
  color: #727272; }

.dashboard-admin-page.sort-menu .form-check:not(:last-child) {
  border-bottom: 1px solid #ddd; }

.dashboard-admin-page .student-card.toggle-show-roadmaps {
  cursor: pointer; }

.dashboard-admin-page .student-card .card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.dashboard-admin-page .student-card .more-btn-desktop {
  position: absolute;
  right: .7em;
  top: .7em; }

.dashboard-admin-page .student-card .name {
  line-height: 1; }

.dashboard-admin-page .student-card-roadmap-link {
  color: inherit;
  text-decoration: none; }

.dashboard-admin-page .student-card .student-dot {
  position: absolute;
  left: 4px;
  top: 1.7em; }

.dashboard-admin-page .student-card .student-card-roadmap-link:hover {
  opacity: 1; }

.dashboard-admin-page .student-card .last-login {
  font-size: 0.8rem;
  text-align: right;
  margin-bottom: 0; }

.dashboard-admin-page .student-card .dropdown {
  position: absolute;
  right: .7em;
  top: .7em; }

.dashboard-admin-page .form-check {
  text-align: left;
  padding-left: 40px; }
  .dashboard-admin-page .form-check:hover {
    cursor: pointer; }
  .dashboard-admin-page .form-check label {
    width: 100%;
    margin-left: 1rem;
    color: #3e3e3e; }
    .dashboard-admin-page .form-check label:hover {
      cursor: pointer; }

.dashboard-admin-page .admin-page-header-wrapper .form-check {
  padding-top: 0;
  padding-bottom: 0; }

.dashboard-admin-page .desktop-page-container .content-wrapper > div:first-child {
  padding-left: 0;
  padding-right: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .dashboard-admin-page .desktop-page-container .content-wrapper > div:first-child > div {
    flex-basis: 49.2%; }
    .dashboard-admin-page .desktop-page-container .content-wrapper > div:first-child > div.infinite-scroll-loader {
      flex-basis: 100%; }

.item-count {
  display: inline-flex;
  line-height: 1;
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 6px;
  height: 23px;
  vertical-align: top; }
  .item-count img {
    height: 14px; }
  .item-count.red-count {
    background-color: #fbd4c6;
    color: #f1561e; }
  .item-count.gray-count {
    background-color: #F4F4F5;
    color: #727272; }

.toggle-fixed-position {
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
  z-index: 10; }

.upcomingcalls-card .more-btn-desktop {
  position: absolute;
  right: .7em;
  top: .7em; }

@media (min-width: 992px) {
  .coach-upcomingcalls-card {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.09) !important;
    border-radius: 10px; } }

.dashboard-coach-add-calls .d-contents {
  display: contents; }

.dashboard-roadmap-card .title {
  font-weight: bold;
  font-size: 0.875rem; }

.dashboard-roadmap-card .progress {
  height: 0.25rem;
  background-color: #DEDEDE; }
  .dashboard-roadmap-card .progress .progress-bar {
    background-color: #5F9CEF;
    transition: width 0.6s ease;
    border-radius: 4px; }

.dashboard-roadmap-card .roadmap-dot {
  position: absolute;
  left: -0.75rem;
  transform: translateY(-50%);
  top: 50%; }

.dashboard-roadmap-card .started-date {
  font-size: 0.8rem;
  text-align: right;
  margin-bottom: 0; }

.col-to-right {
  position: relative; }
  .col-to-right .col-item-to-right {
    position: absolute;
    right: 0; }

.roadmap-default-page {
  min-height: 100vh; }
  .roadmap-default-page .infinite-scroll-container > div:first-child {
    width: 100%; }
  .roadmap-default-page .common-header h1 {
    font-size: 1rem; }
  .roadmap-default-page .common-header .third-column {
    width: 48px; }
    .roadmap-default-page .common-header .third-column svg {
      position: relative;
      right: 5px; }
  .roadmap-default-page .default-stage-thumbnail {
    background-color: #eeeeee;
    border-radius: 8px;
    height: 2rem;
    width: 2rem;
    margin: auto; }
    .roadmap-default-page .default-stage-thumbnail.left-edge {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
    .roadmap-default-page .default-stage-thumbnail.right-edge {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .roadmap-default-page .default-stage-thumbnail svg {
      color: white;
      height: 1rem;
      width: 1rem;
      display: block; }
  .roadmap-default-page .table-of-contents {
    position: -webkit-sticky;
    position: sticky;
    top: 200px;
    max-height: 68vh;
    overflow: auto; }
    .roadmap-default-page .table-of-contents .card:hover {
      cursor: pointer; }
  .roadmap-default-page .stage .card {
    border: 1px solid transparent; }
    @media (min-width: 992px) {
      .roadmap-default-page .stage .card {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.09) !important;
        border-radius: 10px; } }
    .roadmap-default-page .stage .card:hover {
      border: 1px solid #eaeaea; }
    .roadmap-default-page .stage .card .row {
      min-height: 55px; }
  .roadmap-default-page .roadmap-title {
    font-size: 1.25rem; }
  .roadmap-default-page .hidden-student {
    color: red;
    font-size: 0.7rem;
    font-weight: bold;
    border-radius: 5px;
    background-color: #ffc7c7; }
  .roadmap-default-page .card.recent {
    background-color: #D0E0FF;
    box-shadow: 0px 0px 2px rgba(151, 167, 235, 0.4);
    border: 1px solid transparent; }
    .roadmap-default-page .card.recent:hover {
      border: 1px solid #c1d6ff; }
  .roadmap-default-page .card-title {
    font-size: 1rem; }
  .roadmap-default-page .card-text {
    color: #aaaaaa;
    font-size: 0.7rem;
    font-weight: bold; }
    .roadmap-default-page .card-text .badge {
      color: #727272;
      font-size: 0.7rem; }
  .roadmap-default-page .comments svg {
    position: relative;
    top: 1px; }
  .roadmap-default-page .stage {
    position: relative; }
    @media (min-width: 992px) {
      .roadmap-default-page .stage {
        margin-bottom: 1.5rem; } }
    .roadmap-default-page .stage .stage-scroll-to {
      height: 0px;
      position: absolute;
      top: -85px;
      left: 0;
      right: 0;
      z-index: -1; }
      .roadmap-default-page .stage .stage-scroll-to.with-student-header {
        top: -100px; }
      @media (min-width: 992px) {
        .roadmap-default-page .stage .stage-scroll-to {
          top: -205px; }
          .roadmap-default-page .stage .stage-scroll-to.with-student-header {
            top: -215px; } }
    .roadmap-default-page .stage .coach-notes {
      color: #f1561e; }
  .roadmap-default-page .default-stage-thumbnail.red {
    background-color: #f1561e; }
  .roadmap-default-page .default-stage-thumbnail.yellow {
    background-color: #f5d844; }
  .roadmap-default-page .default-stage-thumbnail.green {
    background-color: #1bc573; }
  .roadmap-default-page .dot {
    position: absolute;
    top: 12px;
    left: -12px; }
  .roadmap-default-page .desktop-page-secondary-header-wrapper .back-button-container {
    float: left;
    height: 0.25rem; }
    .roadmap-default-page .desktop-page-secondary-header-wrapper .back-button-container .common-desktop-back-button {
      box-shadow: none; }
  .roadmap-default-page .desktop-page-secondary-header-wrapper .progress {
    height: 0.25rem;
    width: 50%;
    transform: translate(50%, 0); }
    .roadmap-default-page .desktop-page-secondary-header-wrapper .progress .progress-bar {
      background-color: #5F9CEF;
      border-radius: 4px; }
  .roadmap-default-page .desktop-page-secondary-header-wrapper .progress-text {
    color: #727272;
    font-weight: 600;
    font-size: 0.875rem; }

.blue-ocean .roadmap-default-page {
  background-color: #6379BA; }
  .blue-ocean .roadmap-default-page h2, .blue-ocean .roadmap-default-page h1, .blue-ocean .roadmap-default-page .svg-inline--fa {
    color: white; }
  .blue-ocean .roadmap-default-page .common-header {
    background-color: #5E73AF; }
  .blue-ocean .roadmap-default-page .card.recent {
    background-color: #D0E0FF; }
  .blue-ocean .roadmap-default-page .navbar {
    background-color: #5E73AF; }
    .blue-ocean .roadmap-default-page .navbar .navbar-nav .active {
      color: white; }

.roadmap-competency-page {
  position: relative; }
  .roadmap-competency-page .embed {
    display: flex;
    flex-direction: column; }
    .roadmap-competency-page .embed figure {
      flex-grow: 1; }
      .roadmap-competency-page .embed figure > div {
        flex-grow: 1; }
  .roadmap-competency-page .btn-option {
    background-color: transparent;
    box-shadow: none;
    min-height: 27px;
    padding: 0px;
    color: #3e3e3e; }
  .roadmap-competency-page .common-header h1 {
    font-size: 1rem; }
  .roadmap-competency-page .common-header .third-column {
    width: 48px; }
    .roadmap-competency-page .common-header .third-column svg {
      position: relative;
      right: 5px; }
  .roadmap-competency-page h2 {
    display: inline-block; }
  .roadmap-competency-page span {
    font-weight: 400;
    font-size: 0.875rem;
    color: #727272; }
  .roadmap-competency-page .competency-comment .dot {
    position: relative;
    top: 21px; }
  .roadmap-competency-page .competency-comment .initials span {
    color: white; }
  .roadmap-competency-page .competency-comment .user-name {
    font-size: 0.875rem;
    font-weight: bold;
    color: #3e3e3e; }
    .roadmap-competency-page .competency-comment .user-name ~ span {
      font-size: 0.8rem; }
  .roadmap-competency-page .competency-comment .card {
    font-size: 0.875rem;
    color: #3e3e3e; }
    @media (min-width: 992px) {
      .roadmap-competency-page .competency-comment .card {
        background: #F4F4F5; } }
  @media (min-width: 992px) {
    .roadmap-competency-page .competency-comment .mine {
      background: #D0E0FF;
      box-shadow: none; } }
  .roadmap-competency-page .comment-list {
    position: static; }
    .roadmap-competency-page .comment-list .comment-form {
      position: fixed;
      bottom: 3.4rem;
      z-index: 1040;
      left: 0;
      right: 0;
      border-top: 1px solid #cccccc; }
      .roadmap-competency-page .comment-list .comment-form .form-control:focus {
        border: 1px solid #eeeeee; }
      .roadmap-competency-page .comment-list .comment-form .form-group {
        margin-bottom: 0; }
      .roadmap-competency-page .comment-list .comment-form.focused-input {
        position: absolute;
        bottom: 0; }
    .roadmap-competency-page .comment-list .competency-comment:last-of-type {
      padding-bottom: 3rem; }
    .roadmap-competency-page .comment-list .delete-comment {
      display: flex;
      align-items: center;
      justify-content: center; }
      .roadmap-competency-page .comment-list .delete-comment .delete-comment-message-icon {
        margin-top: 1.5rem; }
  .roadmap-competency-page .attachment-button {
    height: auto; }
  .roadmap-competency-page .prev-next-btn-container button svg {
    position: relative;
    top: 2px; }
  @media print {
    .roadmap-competency-page .tab-selector {
      display: none; }
    .roadmap-competency-page .action-item-page-attachment-list ~ button.btn {
      display: none; }
    .roadmap-competency-page .common-header-container .static-placeholder {
      visibility: visible; }
    .roadmap-competency-page .common-header-container .fixed-actual-header {
      display: none; }
    .roadmap-competency-page .common-header .col-auto:first-child, .roadmap-competency-page .common-header .col-auto:last-child {
      display: none; } }
  .roadmap-competency-page .desktop-header-container {
    border-radius: 0; }
    .roadmap-competency-page .desktop-header-container .back-button-container {
      float: left;
      height: 0.25rem; }
    .roadmap-competency-page .desktop-header-container h2 {
      display: block; }
      .roadmap-competency-page .desktop-header-container h2 small {
        color: #727272; }
    .roadmap-competency-page .desktop-header-container .tab-selector {
      background: transparent;
      min-height: unset;
      margin-bottom: 0; }
      .roadmap-competency-page .desktop-header-container .tab-selector::after {
        border-bottom: none; }
      .roadmap-competency-page .desktop-header-container .tab-selector > .col {
        flex-grow: 1;
        max-width: 50%;
        transform: translate(50%, 0); }
      .roadmap-competency-page .desktop-header-container .tab-selector .nav-link {
        font-size: 1rem; }
        .roadmap-competency-page .desktop-header-container .tab-selector .nav-link.active {
          color: #2f80ed; }
      .roadmap-competency-page .desktop-header-container .tab-selector .slider {
        background-color: #2f80ed; }

.desktop-page-container .add-another-action-item {
  display: block;
  color: #727272; }

.desktop-page-container .add-attachment, .desktop-page-container .add-action-item {
  display: block;
  color: #727272; }

.add-note {
  display: block;
  color: #727272; }

.roadmap-competency-page-competency-more-tab img {
  max-width: calc(100vw - 2rem);
  height: auto !important; }

.roadmap-competency-page-competency-more-tab .print-header {
  text-align: center;
  font-weight: bold;
  display: none; }

@media print {
  .roadmap-competency-page-competency-more-tab .print-header {
    display: block; } }

@media (min-width: 992px) {
  .roadmap-competency-page .action-item-container, .roadmap-competency-page .questions-section, .roadmap-competency-page .btn-action-item, .roadmap-competency-page .action-item-page-attachment-list ~ button, .roadmap-competency-page .btn-white, .roadmap-competency-page .assessment {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
    border-radius: 10px; }
  .container.primary-content {
    background: #fff;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05); }
  .description-container {
    font-size: 1rem; } }

.dark .roadmap-competency-page .comment-form {
  border-top: 1px solid #555; }

.roadmap-action-item-page {
  min-height: 100vh; }
  .roadmap-action-item-page .ai-description {
    overflow-wrap: break-word; }
    .roadmap-action-item-page .ai-description img {
      max-width: calc(100% - 30px);
      margin: auto;
      display: block;
      border-radius: 10px; }
    .roadmap-action-item-page .ai-description .media {
      display: block; }
  .roadmap-action-item-page .ai-notes {
    font-weight: 400;
    white-space: pre-line; }
    @media (min-width: 992px) {
      .roadmap-action-item-page .ai-notes {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.09) !important;
        border-radius: 10px; } }
  .roadmap-action-item-page .action-button {
    width: 15em; }
  .roadmap-action-item-page .upload-progress {
    width: 8em; }
  @media (min-width: 992px) {
    .roadmap-action-item-page .form-control {
      border-color: #cccccc; }
      .roadmap-action-item-page .form-control:active, .roadmap-action-item-page .form-control:focus {
        border-color: #6E9FE0; } }
  .roadmap-action-item-page .approve-unapprove-btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    min-width: 9rem; }
    @media (max-width: 1199.98px) {
      .roadmap-action-item-page .approve-unapprove-btn {
        margin-top: 0px !important; } }

.mrm-mt-0_75, .user-components-profile-view .coach-card, .roadmap-action-item-page #formNotes, .roadmap-action-item-page button, .chat-list-row:nth-of-type(1), .user-components-profile-view .coach-card, .roadmap-action-item-page #formNotes, .roadmap-action-item-page button, .chat-list-row:nth-of-type(1) {
  margin-top: 2px !important; }

.rh5v-PlayPause_button {
  width: 50px !important;
  height: 50px !important; }

.rh5v-Captions_button {
  width: 50px !important;
  height: 50px !important; }

.rh5v-Volume_button {
  width: 50px !important;
  height: 50px !important; }

.rh5v-Volume_icon {
  padding: 7px;
  width: 40px !important;
  height: 40px;
  margin-top: -20px; }

.rh5v-Fullscreen_icon {
  padding: 5px;
  width: 40px;
  height: 40px;
  margin-top: -5px;
  margin-left: -5px; }

.rh5v-PlayPause_icon {
  padding: 5px;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -5px; }

.action-item-dialog .modal-content {
  background: #F6F6F7; }

.action-item-dialog .cancel {
  position: absolute;
  cursor: pointer;
  font-weight: 600;
  color: #727272;
  left: 0; }
  .action-item-dialog .cancel:hover {
    opacity: .7; }

.action-item-dialog button {
  height: inherit; }

.action-item-dialog .btn-due-date {
  height: 40px;
  font-weight: normal; }
  .action-item-dialog .btn-due-date.is-invalid {
    box-shadow: 0 0 0px 1px #dc3545; }
  .action-item-dialog .btn-due-date svg {
    color: #007bff; }

.action-item-dialog .react-datepicker__navigation {
  height: 10px; }

.action-item-row {
  display: block;
  border-bottom: 2px solid #F6F6F7; }
  .action-item-row:hover {
    opacity: 1; }
  @media (min-width: 992px) {
    .action-item-row {
      border-bottom: 2px solid #f1f1f1; } }

.action-item-row:last-child {
  border-bottom: none; }

.action-item {
  text-decoration: inherit;
  color: #3e3e3e; }
  .action-item a {
    text-decoration: none;
    color: inherit; }
  .action-item .ai-description {
    font-size: 1rem;
    font-weight: 400;
    line-height: 22px; }
    .action-item .ai-description .due-over {
      color: #f1561e;
      display: block;
      margin-top: -4px; }
    .action-item .ai-description .due-completed {
      color: #dddddd; }
    .action-item .ai-description .due-default {
      color: #aaaaaa;
      display: block;
      margin-top: -4px; }
    .action-item .ai-description .file-attachments {
      color: #aaaaaa;
      font-weight: bold; }
  .action-item .ai-status {
    background-color: #eeeeee;
    border-radius: 8px;
    max-width: 2em;
    display: flex;
    height: 2em; }
  .action-item .blank a {
    height: 2em;
    width: 2em; }
  .action-item .delete, .action-item .edit {
    cursor: pointer; }
  .action-item .icon-box svg:hover {
    opacity: .7; }
  .action-item .green {
    background-color: #1bc573; }
  .action-item .text-right {
    position: relative;
    right: 8px;
    color: #727272; }

.roadmap-components-competency-page-action-items .action-item-container {
  background-color: white;
  border-radius: 10px; }
  .roadmap-components-competency-page-action-items .action-item-container .action-item-link {
    text-decoration: none; }

@media print {
  .roadmap-components-competency-page-action-items button.btn {
    display: none; } }

.roadmap-components-competency-page-competency-learn-tab .print-header {
  text-align: center;
  font-weight: bold;
  display: none; }

.roadmap-components-competency-page-competency-learn-tab .coach-notes {
  font-size: 0.875rem;
  color: #f1561e; }

.roadmap-components-competency-page-competency-learn-tab .description-container {
  color: #3e3e3e;
  font-size: 1rem;
  overflow-x: hidden; }
  @media (min-width: 992px) {
    .roadmap-components-competency-page-competency-learn-tab .description-container {
      font-size: 1rem; } }
  .roadmap-components-competency-page-competency-learn-tab .description-container img {
    max-width: calc(100vw - 30px);
    height: auto !important; }

.roadmap-components-competency-page-competency-learn-tab .embed {
  display: flex;
  flex-direction: column; }
  .roadmap-components-competency-page-competency-learn-tab .embed figure {
    flex-grow: 1; }
    .roadmap-components-competency-page-competency-learn-tab .embed figure > div {
      flex-grow: 1; }

.roadmap-components-competency-page-competency-learn-tab .notes-container a {
  text-decoration: none; }

.roadmap-components-competency-page-competency-learn-tab .notes-container .notes-description a {
  background-color: #ffffff;
  display: flex;
  flex-flow: column;
  border-radius: 10px; }
  .roadmap-components-competency-page-competency-learn-tab .notes-container .notes-description a:hover {
    opacity: 1; }
  .roadmap-components-competency-page-competency-learn-tab .notes-container .notes-description a pre {
    font-size: 1rem;
    color: #727272;
    font-family: inherit;
    white-space: pre-wrap;
    word-wrap: break-word;
    font-family: 'Open Sans'; }

.roadmap-components-competency-page-competency-learn-tab .notes-container pre {
  font-size: 1rem;
  color: #727272;
  font-family: inherit;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: 'Open Sans'; }

@media print {
  .roadmap-components-competency-page-competency-learn-tab .print-header {
    display: block; } }

.roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container {
  background-color: white;
  border-radius: 10px;
  color: #3e3e3e;
  min-height: 3em; }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container p {
    font-size: 1rem;
    color: #3e3e3e;
    line-height: 20px; }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .slider-container .rc-slider::before {
    position: absolute;
    content: '';
    border: 4px solid rgba(255, 255, 255, 0.55);
    height: 1rem;
    z-index: 10;
    left: 26.5%; }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .slider-container .rc-slider::after {
    position: absolute;
    content: '';
    border: 4px solid rgba(255, 255, 255, 0.55);
    height: 1rem;
    right: 26.5%; }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .slider-container .rc-slider .rc-slider-rail {
    background: linear-gradient(to right, #f1561e, #f5d844, #1bc573);
    height: 1rem;
    padding: 0;
    left: -0.5rem;
    width: calc(100% + 1rem);
    border-radius: 10px; }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .slider-container .rc-slider .rc-slider-step {
    display: none; }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .slider-container .rc-slider .rc-slider-mark {
    top: 30px; }
    .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .slider-container .rc-slider .rc-slider-mark:first-child {
      display: none; }
    .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .slider-container .rc-slider .rc-slider-mark .rc-slider-mark-text:first-of-type {
      visibility: hidden; }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .slider-container .rc-slider .rc-slider-handle {
    margin-top: -10px;
    width: 1.875rem;
    height: 1.875rem;
    border: 1px solid #F4F4F5;
    background-color: white;
    border-radius: 10px; }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .selected {
    font-size: 30px;
    vertical-align: inherit;
    display: inline;
    display: initial; }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .assessment {
    border-bottom: 2px solid #F6F8F9;
    cursor: pointer; }
    .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .assessment.red.active {
      background-color: #fac2ae;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px; }
    .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .assessment.yellow.active {
      background-color: #fbf1bd; }
    .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .assessment.green.active {
      background-color: #bbf6da;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px; }
    .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .assessment .circle {
      border-radius: 50%;
      width: 20px;
      height: 20px;
      margin: auto;
      display: block;
      position: relative;
      left: 6px; }
      .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .assessment .circle.selected:before {
        position: absolute;
        content: ' ';
        width: 60%;
        height: 60%;
        border-radius: 50%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); }
    .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .assessment .card-title {
      font-size: 1rem;
      line-height: 22px; }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .red .selected:before {
    background-color: #f1561e; }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .red .circle {
    border: 1px solid #f1561e; }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .yellow .selected:before {
    background-color: #f5d844; }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .yellow .circle {
    border: 1px solid #f5d844; }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .green {
    border-bottom: none; }
    .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .green .selected:before {
      background-color: #1bc573; }
    .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .green .circle {
      border: 1px solid #1bc573; }

.roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-slider-container {
  min-height: 8em; }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-slider-container p {
    margin-left: 4px; }

.roadmap-components-competency-page-competency-learn-tab .self-reflection .btn-bottom-left-rounded {
  border-radius: 0;
  border-bottom-left-radius: 10px; }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .btn-bottom-left-rounded.active {
    background-color: #007bff;
    color: white; }

.roadmap-components-competency-page-competency-learn-tab .self-reflection .btn-bottom-right-rounded {
  border-radius: 0;
  border-bottom-right-radius: 10px; }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .btn-bottom-right-rounded.active {
    background-color: #007bff;
    color: white; }

.roadmap-components-competency-page-competency-learn-tab .self-reflection .btn-bottom-left-rounded svg, .roadmap-components-competency-page-competency-learn-tab .self-reflection .btn-bottom-right-rounded svg {
  vertical-align: middle; }

.roadmap-components-competency-page-competency-learn-tab .self-reflection .border-br-none {
  border-bottom-right-radius: unset !important; }

.roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-approve {
  margin-top: 1px; }

.roadmap-components-competency-page-competency-learn-tab .questions-section {
  background-color: white;
  border-radius: 10px; }
  .roadmap-components-competency-page-competency-learn-tab .questions-section .question-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 22px;
    min-height: 58px; }
    .roadmap-components-competency-page-competency-learn-tab .questions-section .question-item .answer {
      font-size: 1rem;
      color: #727272; }
    .roadmap-components-competency-page-competency-learn-tab .questions-section .question-item pre {
      max-width: 100%;
      font-size: 1rem;
      font-family: inherit;
      white-space: pre-wrap;
      word-wrap: break-word;
      margin-bottom: 0;
      color: #3e3e3e; }
    .roadmap-components-competency-page-competency-learn-tab .questions-section .question-item:not(:last-child) {
      border-bottom: 2px solid #F6F6F7; }
      @media (min-width: 992px) {
        .roadmap-components-competency-page-competency-learn-tab .questions-section .question-item:not(:last-child) {
          border-bottom: 2px solid #f1f1f1; } }
    .roadmap-components-competency-page-competency-learn-tab .questions-section .question-item svg {
      margin-left: .625rem; }
    .roadmap-components-competency-page-competency-learn-tab .questions-section .question-item:hover {
      cursor: pointer; }

@media (min-width: 992px) {
  .roadmap-components-competency-page-competency-learn-tab .notes-container .notes-description a {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
    border-radius: 10px; }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container {
    display: flex;
    box-shadow: none;
    border-radius: 0;
    align-items: stretch; }
    .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .assessment {
      margin-left: 0.25rem;
      margin-right: 0.25rem;
      flex: 1 0;
      border-radius: 10px;
      position: relative; }
      .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .assessment:first-of-type {
        margin-left: 0; }
      .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .assessment:last-of-type {
        margin-right: 0; }
      .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .assessment .assessment-header {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        height: 0.75rem;
        position: absolute;
        top: 0; }
      .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .assessment .card-body {
        padding-top: 1.5rem; }
      .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .assessment.red .assessment-header {
        background-color: #f1561e; }
      .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .assessment.red.active {
        border: 2px solid #f1561e; }
      .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .assessment.yellow .assessment-header {
        background-color: #f5d844; }
      .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .assessment.yellow.active {
        border: 2px solid #f5d844; }
      .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .assessment.green .assessment-header {
        background-color: #1bc573; }
      .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .assessment.green.active {
        border: 2px solid #1bc573; }
      .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .assessment.active .assessment-header {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem; }
    .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .assessment-approve-desktop-container {
      height: 0;
      justify-content: center; }
      .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .assessment-approve-desktop-container .assessment-approve {
        position: relative;
        top: -0.5rem; }
        .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .assessment-approve-desktop-container .assessment-approve button {
          border-radius: 10px; }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-slider-container {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    padding: .75rem;
    display: block; } }

.action-item-edit-menu-modal .modal-dialog .modal-content {
  background-color: transparent;
  border: none; }

.action-item-edit-menu-modal .main-buttons {
  border-radius: 10px; }

@media (min-width: 768px) {
  .action-item-edit-menu-modal .modal-dialog {
    display: flex;
    align-items: center;
    height: 100%; }
  .action-item-edit-menu-modal .main-buttons {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  .action-item-edit-menu-modal .cancel {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .action-item-edit-menu-modal .delete {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; } }

.roadmap-components-action-item-page-edit-menu {
  text-align: right; }

.roadmap-components-action-item-page-attachment button {
  height: inherit;
  max-width: 13em; }

.roadmap-components-action-item-page-attachment .cancel {
  position: absolute;
  left: 0;
  top: 0; }
  .roadmap-components-action-item-page-attachment .cancel:hover {
    opacity: .5; }

.roadmap-components-action-item-page-attachment .upload-progress {
  width: 8em; }

.roadmap-components-action-item-page-attachment .failed {
  color: #af0000; }

.roadmap-components-action-item-page-attachment .completed {
  color: darkgreen; }

.action-item-page-attachment-list .attachment {
  background-color: white;
  border-radius: 10px;
  align-items: center;
  display: flex;
  height: 55px; }
  .action-item-page-attachment-list .attachment .attachment-type-icon {
    color: #727272; }
  .action-item-page-attachment-list .attachment .file-name {
    color: #3e3e3e;
    flex-grow: 1;
    text-overflow: ellipsis;
    flex-basis: 1em;
    white-space: nowrap;
    font-size: 0.875rem;
    overflow: hidden;
    text-decoration: underline; }
    .action-item-page-attachment-list .attachment .file-name:hover {
      opacity: .7; }
  .action-item-page-attachment-list .attachment .file-type {
    color: #727272;
    font-size: 0.875rem;
    display: inline-block; }
  .action-item-page-attachment-list .attachment .delete {
    cursor: pointer; }
    .action-item-page-attachment-list .attachment .delete:hover {
      opacity: .5; }
    .action-item-page-attachment-list .attachment .delete svg {
      color: #727272; }

.roadmap-components-competency-page-competency-comments-tab .print-header {
  text-align: center;
  font-weight: bold;
  display: none; }

@media print {
  .roadmap-components-competency-page-competency-comments-tab .print-header {
    display: block; }
  .roadmap-components-competency-page-competency-comments-tab .comment-form {
    display: none !important; } }

@media (min-width: 992px) {
  .roadmap-components-competency-page-competency-comments-tab .comments-anchor {
    margin-bottom: 96px; } }

@media (min-width: 1200px) {
  .roadmap-components-competency-page-competency-comments-tab .comment-list {
    padding-left: 11rem;
    padding-right: 11rem; } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .roadmap-components-competency-page-competency-comments-tab .comment-list {
    padding-left: 7rem;
    padding-right: 7rem; } }

@media (min-width: 992px) {
  .roadmap-components-competency-page-competency-comments-tab .comment-list {
    min-height: calc(100vh - 96px);
    margin-bottom: 0; } }

.roadmap-components-competency-page-competency-comments-tab .desktop-page-container .comment-form {
  position: static;
  left: auto;
  left: initial;
  right: inherit;
  background: #ffffff;
  border-top: none;
  padding-left: 0;
  padding-top: 8px !important; }

.roadmap-components-competency-page-competency-comments-tab .desktop-page-container .form-control {
  border-color: #cccccc; }
  .roadmap-components-competency-page-competency-comments-tab .desktop-page-container .form-control:active, .roadmap-components-competency-page-competency-comments-tab .desktop-page-container .form-control:focus {
    border-color: #2f80ed !important; }

.roadmap-components-competency-page-competency-comments-tab .bottom {
  position: relative;
  width: 100%; }
  @media (min-width: 1200px) {
    .roadmap-components-competency-page-competency-comments-tab .bottom {
      max-width: 47rem; } }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .roadmap-components-competency-page-competency-comments-tab .bottom {
      max-width: 44rem; } }

.roadmap-components-competency-page-competency-comments-tab .bottom .sticky {
  position: fixed;
  width: inherit;
  max-width: inherit;
  bottom: 0px; }

.roadmap-edit-assigned-coaches-page .common-header button {
  background-color: transparent;
  box-shadow: none; }
  .roadmap-edit-assigned-coaches-page .common-header button:hover {
    opacity: .7; }

.roadmap-edit-assigned-coaches-page .common-header .btn-save {
  color: #007bff; }

.roadmap-edit-assigned-coaches-page .default-roadmap-thumbnail {
  background-color: #D0E0FF;
  border-radius: 10px;
  height: 1.2em;
  width: 1.2em;
  color: white; }

.roadmap-edit-assigned-coaches-page .coach-type, .roadmap-edit-assigned-coaches-page .coach-email {
  color: #727272; }

.roadmap-edit-assigned-coaches-page .coach-card p {
  word-break: break-all;
  line-height: 1; }

.roadmap-edit-assigned-coaches-page .coach-card button {
  height: inherit;
  font-size: 0.8rem; }
  .roadmap-edit-assigned-coaches-page .coach-card button.unassign {
    background-color: inherit;
    box-shadow: none; }
    .roadmap-edit-assigned-coaches-page .coach-card button.unassign:hover {
      background-color: white; }

.roadmap-note-page .common-header button {
  background-color: transparent;
  box-shadow: none; }
  .roadmap-note-page .common-header button:hover {
    opacity: .7; }

.roadmap-note-page .common-header .btn-save {
  color: #007bff; }

.roadmap-answer-page .btn-save {
  color: #007bff;
  background-color: transparent;
  box-shadow: none; }

.roadmap-answer-page .btn-cancel {
  background-color: transparent;
  box-shadow: none; }

.roadmap-components-student-info {
  display: flex;
  justify-content: center;
  align-items: center; }
  .roadmap-components-student-info strong {
    color: #3e3e3e; }

.messages-default-page .mobile-page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 50vh;
  position: relative; }
  .messages-default-page .mobile-page-wrapper .msg-room ~ .common-bottom-nav .navbar {
    border-top: none !important; }
  .messages-default-page .mobile-page-wrapper .border-bottom-header {
    border-bottom: 1px solid #cccccc; }
  .messages-default-page .mobile-page-wrapper .msg-room {
    position: static;
    flex: 1 1; }
  .messages-default-page .mobile-page-wrapper .common-header button {
    color: #3e3e3e; }

.messages-default-page .new-msg-btn {
  color: #3e3e3e; }

.messages-default-page .desktop-page-container {
  padding-left: 0;
  padding-right: 0;
  background: white;
  border-radius: 10px; }
  .messages-default-page .desktop-page-container .conversations-list-wrapper {
    border-right: 1px solid #cccccc;
    min-height: 24rem; }
    .messages-default-page .desktop-page-container .conversations-list-wrapper .container {
      width: 100%;
      max-height: 500px;
      overflow: scroll; }
    .messages-default-page .desktop-page-container .conversations-list-wrapper .conversations-list-header h2 {
      line-height: 1.76; }
  .messages-default-page .desktop-page-container .search {
    border: 1px solid #eeeeee; }
  .messages-default-page .desktop-page-container hr {
    height: 1px;
    width: 100%; }
  .messages-default-page .desktop-page-container .conversation-avatar {
    position: relative;
    min-width: 3rem; }
    .messages-default-page .desktop-page-container .conversation-avatar .common-user-avatar {
      position: absolute; }
      .messages-default-page .desktop-page-container .conversation-avatar .common-user-avatar:first-child {
        top: 0.25rem;
        left: 0.25rem; }
      .messages-default-page .desktop-page-container .conversation-avatar .common-user-avatar:last-child {
        bottom: 0.25rem;
        right: 0.25rem; }
  .messages-default-page .desktop-page-container .new-msg-btn {
    position: relative; }
  .messages-default-page .desktop-page-container .common-search-bar input {
    background-color: #F4F4F5; }
  .messages-default-page .desktop-page-container .chat-list-row {
    border-radius: 10px; }
    .messages-default-page .desktop-page-container .chat-list-row:first-of-type {
      margin-top: 0px; }
    .messages-default-page .desktop-page-container .chat-list-row:hover {
      cursor: pointer;
      background: #f7f7f7; }
  .messages-default-page .desktop-page-container .selected-chat {
    background-color: #EAF3FF; }
    .messages-default-page .desktop-page-container .selected-chat:hover {
      background-color: #EAF3FF; }
  .messages-default-page .desktop-page-container .conversation-messages-wrapper {
    position: relative; }
    .messages-default-page .desktop-page-container .conversation-messages-wrapper .container {
      width: 100%; }
    .messages-default-page .desktop-page-container .conversation-messages-wrapper .conversation-messages-header {
      display: flex;
      height: 47px; }
      .messages-default-page .desktop-page-container .conversation-messages-wrapper .conversation-messages-header h2 {
        line-height: 1.6; }
      .messages-default-page .desktop-page-container .conversation-messages-wrapper .conversation-messages-header .common-user-avatar {
        display: inline-block; }
      .messages-default-page .desktop-page-container .conversation-messages-wrapper .conversation-messages-header button {
        position: absolute;
        right: 0; }
      .messages-default-page .desktop-page-container .conversation-messages-wrapper .conversation-messages-header h3 {
        display: inline;
        font-weight: bold;
        line-height: 1.6;
        margin-top: 2px; }
      .messages-default-page .desktop-page-container .conversation-messages-wrapper .conversation-messages-header .chat-list-row {
        padding-left: 5px; }
      .messages-default-page .desktop-page-container .conversation-messages-wrapper .conversation-messages-header .message-group-icon {
        position: relative;
        top: -21px; }
      .messages-default-page .desktop-page-container .conversation-messages-wrapper .conversation-messages-header .chat-list-row:hover {
        background: transparent;
        cursor: auto;
        cursor: initial; }

@media (min-width: 992px) {
  .new-message-modal .form-control {
    background-color: #F4F4F5; } }

.new-message-modal .modal-content {
  background: #F6F6F7;
  min-height: 93vh; }
  @media (min-width: 992px) {
    .new-message-modal .modal-content {
      background: #fff; } }

.new-message-modal .modal-header {
  border-bottom: none; }
  .new-message-modal .modal-header .modal-title {
    width: 100%;
    font-size: 1.125rem;
    line-height: 1.25rem;
    color: #3e3e3e;
    position: relative;
    font-weight: 700; }
    .new-message-modal .modal-header .modal-title .cancel-btn, .new-message-modal .modal-header .modal-title .next-btn {
      padding: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%); }
    .new-message-modal .modal-header .modal-title .cancel-btn {
      left: 0;
      color: #727272; }
    .new-message-modal .modal-header .modal-title .next-btn {
      right: 0; }

.new-message-modal .modal-content {
  height: 400px; }

.new-message-modal .modal-body {
  overflow-y: auto;
  padding: 0px; }

.new-message-modal #groupName {
  max-width: -webkit-fill-available;
  max-width: -moz-available;
  max-width: stretch; }

.new-message-modal .user-row {
  min-height: 3rem; }
  .new-message-modal .user-row:last-of-type {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; }
  .new-message-modal .user-row:hover {
    cursor: pointer;
    background: #fff; }
    @media (min-width: 992px) {
      .new-message-modal .user-row:hover {
        background: #f7f7f7; } }

.new-message-modal .search-bar-container {
  padding: 0 1rem;
  border-bottom: 1px solid #E5E5E5; }
  .new-message-modal .search-bar-container .common-search-bar {
    padding-bottom: 1rem; }

.new-message-modal .group-message-link-container {
  border-bottom: 1px solid #E5E5E5; }
  .new-message-modal .group-message-link-container button {
    font-size: 1rem; }
    .new-message-modal .group-message-link-container button:hover {
      text-decoration: none;
      color: #007bff; }
  .new-message-modal .group-message-link-container:hover {
    background-color: #eaf1ff; }

.new-message-modal .selected-participants {
  position: relative;
  background: #F6F6F7;
  border-bottom: 1px solid #E5E5E5; }
  @media (min-width: 992px) {
    .new-message-modal .selected-participants {
      background: #ffffff; } }
  .new-message-modal .selected-participants .participant {
    position: relative; }
  .new-message-modal .selected-participants .participant-remove-btn {
    position: absolute;
    font-size: 0.75rem;
    color: #aaaaaa;
    cursor: pointer;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    left: 29px;
    top: -8px;
    right: 0px;
    cursor: pointer;
    margin-left: 0px; }
  .new-message-modal .selected-participants .participant-name {
    font-size: 0.875rem;
    font-weight: bold;
    color: #3e3e3e;
    display: block;
    min-width: 42px;
    text-align: center; }

.new-message-modal .group-participants hr {
  border-bottom: 1px solid #E5E5E5;
  height: 0;
  margin-left: -1rem;
  margin-right: -1rem; }

.new-message-modal .group-participants .user-row {
  position: relative; }
  .new-message-modal .group-participants .user-row svg {
    position: absolute;
    right: 1rem;
    color: #aaaaaa; }
    .new-message-modal .group-participants .user-row svg.selected {
      color: #2f80ed; }

.new-message-modal .user-row {
  display: flex;
  align-items: center; }
  .new-message-modal .user-row .initials {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: white; }
  .new-message-modal .user-row .user-name {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #3e3e3e;
    margin-bottom: 0;
    font-weight: bold;
    padding-left: .5rem; }

.msg-list {
  padding-bottom: 5rem;
  overflow-y: auto; }
  .msg-list .msg-text {
    background-color: #F4F4F5; }
  @media (max-width: 991.98px) {
    .msg-list .msg-list-row {
      margin-left: 0px;
      margin-right: 0px; } }
  .msg-list .msg-list-row .common-user-avatar {
    position: absolute; }
  .msg-list .msg-list-row .msg-detail .user-info {
    display: flex;
    align-items: center; }
    .msg-list .msg-list-row .msg-detail .user-info h5 {
      font-weight: bold;
      font-size: 0.875rem;
      line-height: 1.25rem;
      margin-bottom: 0; }
    .msg-list .msg-list-row .msg-detail .user-info span {
      font-size: 0.8rem;
      line-height: 1rem; }
  .msg-list .msg-list-row .msg-detail .msg-text {
    font-size: 0.875rem;
    line-height: 1.125rem;
    border-radius: 0.625rem;
    white-space: pre-wrap;
    word-wrap: break-word;
    flex-grow: 1; }
    .msg-list .msg-list-row .msg-detail .msg-text.mine {
      background-color: #D0E0FF; }

.mine {
  background-color: #D0E0FF; }

.msg-room .msg-form {
  position: fixed;
  display: flex;
  align-items: center;
  left: 0;
  right: 0;
  bottom: 55px;
  border-top: 1px solid #cccccc;
  z-index: 1040; }
  .msg-room .msg-form .container {
    padding: 0px; }
  .msg-room .msg-form .form-control:focus {
    border: 1px solid #eeeeee; }
  .msg-room .msg-form.focused-input {
    position: absolute;
    bottom: 0; }

@media (min-width: 992px) {
  .msg-room .msg-list {
    max-height: 500px; }
  .msg-room .msg-form {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1000;
    background: #ffffff;
    border-bottom-right-radius: 10px; }
    .msg-room .msg-form .form-control {
      border-color: #cccccc; }
      .msg-room .msg-form .form-control:focus, .msg-room .msg-form .form-control:active {
        border-color: #cccccc; } }

@media (max-width: 991.98px) {
  .msg-room .msg-text {
    background-color: #ffffff; } }

.no-messages .svg-inline--fa {
  color: #007bff; }

.chat-list-row {
  position: relative; }
  .chat-list-row .chat-list-item {
    flex-direction: column;
    max-width: 90%; }
  .chat-list-row .dot {
    position: absolute;
    top: 27px;
    left: -11px; }
    @media (min-width: 992px) {
      .chat-list-row .dot {
        top: 30px;
        left: 4px; } }
  .chat-list-row .conversation-avatar {
    position: relative;
    width: 47px; }
    .chat-list-row .conversation-avatar .message-roadmap-icon {
      width: 34px;
      height: 34px;
      border: #FFF solid 2px;
      border-radius: 50%;
      background-color: #cccccc;
      text-align: center;
      position: absolute;
      left: 51%;
      top: 44%; }
      .chat-list-row .conversation-avatar .message-roadmap-icon svg {
        margin-top: 5px; }
    .chat-list-row .conversation-avatar .message-group-icon svg {
      width: 47px;
      height: 47px;
      padding: 9px;
      border-radius: 50%;
      background-color: #cccccc;
      border: #F5F8F9 solid 1px; }
  .chat-list-row .chat-info {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .chat-list-row .chat-info h5 {
      font-weight: bold;
      font-size: 1rem;
      line-height: 1.25rem;
      margin-bottom: 0; }
    .chat-list-row .chat-info span {
      font-size: 0.875rem;
      line-height: 0.875rem; }
  .chat-list-row .last-msg {
    font-size: 0.875rem;
    line-height: 1rem;
    white-space: pre-wrap;
    word-wrap: break-word;
    margin-bottom: 0; }
  .chat-list-row strong {
    color: #3e3e3e; }

.dark .messages-components-conversations-list .chat-list-row strong {
  color: white; }

.dark .messages-components-conversations-list .chat-list-row .conversation-avatar .message-roadmap-icon {
  border: 2px solid #2C3942; }

.notifications-default-page {
  min-height: 50vh; }

.mark-all-as-read-modal .modal-content {
  background-color: transparent;
  border: none; }

.notifications-components-default-page-notifications-list .no-notifications {
  text-align: center; }
  .notifications-components-default-page-notifications-list .no-notifications .fa-bell {
    color: #007bff !important;
    width: 2.5rem !important;
    font-size: 2.5rem !important; }

.notifications-components-default-page-notifications-list .notification-row {
  cursor: pointer;
  position: relative;
  font-size: 0.875rem; }
  .notifications-components-default-page-notifications-list .notification-row p {
    margin-bottom: 0;
    line-height: 16px; }
  .notifications-components-default-page-notifications-list .notification-row .unread-marker {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #2f80ed;
    display: inline-block;
    position: absolute;
    top: 36px;
    left: -11px; }

.notifications-components-default-page-notifications-list hr {
  height: 1px;
  margin: 0 -1.5rem; }

.manage-user-page .profile-card .card-body {
  border-radius: 10px; }

.manage-user-page .no-roadmap {
  color: #727272;
  font-size: 0.8rem;
  font-style: italic;
  text-align: center; }

.manage-user-page .active-icon.active {
  color: green; }

.manage-user-page .active-icon.inactive {
  color: lightgray; }

.manage-user-page .btn-edit-assigned-roadmaps {
  text-decoration: none;
  background-color: #F4F4F5;
  line-height: 2;
  width: 15em; }
  @media (min-width: 992px) {
    .manage-user-page .btn-edit-assigned-roadmaps {
      position: absolute;
      top: 1rem;
      right: 1rem; } }

.manage-user-page .info {
  font-size: 0.875rem; }

.manage-user-page .field {
  color: #727272; }

.manage-user-page .edit-profile-button {
  position: absolute;
  top: 0;
  right: 15px; }

.manage-user-page .desktop-view-container .common-desktop-back-button {
  background: #fff; }

.manage-user-page .desktop-view-container .users-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .manage-user-page .desktop-view-container .users-cards-container > div {
    flex-basis: 49.2%; }

.manage-user-page .mobile-view-container .avatar {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -60%); }

.manage-edit-user-roadmap-page .avatar {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -60%); }

.manage-edit-user-roadmap-page .card-body {
  border-radius: 10px; }

.manage-edit-user-roadmap-page .common-header button {
  background-color: transparent;
  box-shadow: none; }
  .manage-edit-user-roadmap-page .common-header button:hover {
    opacity: .7; }

.manage-edit-user-roadmap-page .common-header .btn-save {
  color: #007bff; }

.manage-edit-user-roadmap-component .no-roadmaps {
  font-style: italic;
  color: #aaaaaa;
  text-align: center; }

.manage-edit-user-roadmap-component .card-in-card {
  background-color: #fff;
  min-height: 55px;
  line-height: 20px; }

.manage-edit-user-roadmap-component .roadmap-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  font-size: 1rem;
  border-bottom: 1px solid #ebebeb; }
  .manage-edit-user-roadmap-component .roadmap-title button {
    height: inherit;
    font-size: 0.875rem; }
    .manage-edit-user-roadmap-component .roadmap-title button.unassign {
      background-color: inherit;
      box-shadow: none; }
      .manage-edit-user-roadmap-component .roadmap-title button.unassign:hover {
        background-color: white; }

.manage-edit-user-roadmap-modal .modal-header .common-user-avatar {
  display: inline-block;
  vertical-align: middle; }

.manage-edit-user-roadmap-modal .modal-header p {
  margin-bottom: 0;
  text-align: center; }

.manage-edit-user-profile-page .common-header button {
  background-color: transparent;
  box-shadow: none; }
  .manage-edit-user-profile-page .common-header button:hover {
    opacity: .7; }

.manage-edit-user-profile-page .common-header .btn-save {
  color: #007bff; }

.manage-edit-user-profile-modal .modal-header {
  position: fixed;
  width: 498px;
  z-index: 1100;
  background: white; }

.manage-edit-user-profile-component label {
  margin-bottom: 0; }

.manage-edit-user-profile-component .error-message {
  color: #dc3545; }

.manage-edit-user-profile-component .action-button {
  width: 17em; }

.manage-edit-user-profile-component .account-photo {
  border-radius: 50%;
  width: 4em;
  height: 4em; }

.manage-edit-user-profile-component .invalid-avatar {
  font-size: 1rem; }
  .manage-edit-user-profile-component .invalid-avatar.is-invalid {
    color: #dc3545; }

@media (min-width: 992px) {
  .manage-edit-user-profile-component .form-control {
    border-color: #cccccc; }
    .manage-edit-user-profile-component .form-control:active, .manage-edit-user-profile-component .form-control:focus {
      border-color: #6E9FE0; } }

.desktop-page-secondary-header-wrapper .btn-save {
  float: right;
  background: #2f80ed;
  color: #fff; }

.manage-groups-page .btn-edit {
  background-color: #F4F4F5; }

@media (max-width: 991.98px) {
  .manage-groups-page .common-custom-table {
    border-radius: 0px; } }

.manage-group-view-page .common-header .col-1 {
  padding-left: 0; }

.manage-group-view-page .group-name, .manage-group-view-page strong {
  font-size: 1.125rem; }

.manage-group-view-page .avatar {
  width: 5em;
  height: 5em;
  color: #007bff;
  border-radius: 50%;
  display: flex;
  background-color: white;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -80%);
  border: 2px solid #eeeeee; }

.manage-group-view-page .table {
  font-size: 0.875rem; }

.manage-group-view-page .btn-edit-group {
  position: absolute;
  right: .5em;
  top: .5em; }

.manage-group-view-page .card-in-card {
  background-color: #fff;
  font-weight: bold;
  min-height: 55px;
  line-height: 20px;
  align-items: center; }
  .manage-group-view-page .card-in-card .name {
    color: #3e3e3e; }

.manage-group-view-page .common-desktop-back-button {
  background: #ffffff; }

.manage-group-edit-page .btn-save {
  color: #007bff;
  background-color: transparent;
  box-shadow: none; }
  @media (min-width: 992px) {
    .manage-group-edit-page .btn-save {
      background-color: #007bff;
      color: #fff; } }

.manage-group-edit-page .btn-cancel {
  background-color: transparent;
  box-shadow: none; }

@media (min-width: 992px) {
  .manage-group-edit-page .form-control:active, .manage-group-edit-page .form-control:focus {
    border-color: #6E9FE0; } }

.manage-accounts-page .btn {
  font-size: 0.8rem; }

.manage-accounts-page .search {
  background: #F1F2F3;
  -webkit-appearance: none; }
  @media (max-width: 991.98px) {
    .manage-accounts-page .search {
      background: #e1e6eb; } }

.manage-accounts-page .btn-edit {
  color: #727272;
  background-color: #F4F4F5;
  min-height: auto;
  font-weight: bold;
  font-size: 0.8rem; }

.manage-accounts-page .common-search-bar {
  flex-grow: 1;
  margin-right: 1rem; }

@media (max-width: 991.98px) {
  .manage-accounts-page .common-custom-table {
    border-radius: 0px; } }

.manage-roadmaps-page .status-active {
  display: block;
  background-color: #23d37b;
  border-radius: 10px;
  color: white;
  max-width: 80px;
  margin: auto;
  font-weight: 600; }

.manage-roadmaps-page .common-search-bar {
  flex-grow: 1;
  margin-right: 1rem; }

.manage-roadmaps-page .search {
  background: #F1F2F3;
  -webkit-appearance: none; }
  @media (max-width: 991.98px) {
    .manage-roadmaps-page .search {
      background: #e1e6eb; } }

.manage-roadmaps-page .btn-action {
  background-color: #F4F4F5;
  min-height: 30px; }
  .manage-roadmaps-page .btn-action svg {
    vertical-align: middle; }

@media (max-width: 991.98px) {
  .manage-roadmaps-page .common-custom-table {
    border-radius: 0px; } }

.desktop-page-container .common-custom-table th, .desktop-page-container .common-custom-table td {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 1rem; }

.desktop-page-container .common-custom-table a {
  font-weight: 600; }

.manage-add-roadmap-page .common-header button {
  background-color: transparent;
  box-shadow: none; }
  .manage-add-roadmap-page .common-header button:hover {
    opacity: .7; }

.manage-add-roadmap-page .common-header .btn-save {
  color: #007bff; }

.manage-edit-roadmap-page .stage-competency {
  background-color: white;
  border-radius: 10px;
  align-items: flex-start;
  display: flex;
  color: #3e3e3e;
  font-weight: normal;
  flex-direction: column; }
  .manage-edit-roadmap-page .stage-competency:hover {
    cursor: pointer;
    opacity: 1; }
  .manage-edit-roadmap-page .stage-competency .badge {
    color: #727272; }
  .manage-edit-roadmap-page .stage-competency ~ button {
    position: absolute;
    top: 0.5rem;
    right: 1rem; }

@media (max-width: 991.98px) {
  .manage-edit-roadmap-page > .container {
    padding-left: 0;
    padding-right: 0;
    max-width: none; } }

@media (min-width: 992px) {
  .manage-edit-roadmap-page > .container {
    padding-left: 2rem; } }

.manage-edit-roadmap-page .stage-item {
  border-bottom: 1px solid #cccccc; }
  .manage-edit-roadmap-page .stage-item.stage-item__dragging {
    border-bottom: none; }
  .manage-edit-roadmap-page .stage-item .stage-header-info {
    position: relative; }
    .manage-edit-roadmap-page .stage-item .stage-header-info .stage-scroll-to {
      height: 0px;
      position: absolute;
      top: -115px;
      left: 0;
      right: 0;
      z-index: -1; }
      @media (min-width: 992px) {
        .manage-edit-roadmap-page .stage-item .stage-header-info .stage-scroll-to {
          top: -170px; } }
  .manage-edit-roadmap-page .stage-item:hover {
    cursor: pointer; }
  @media (min-width: 992px) {
    .manage-edit-roadmap-page .stage-item {
      border-bottom: none; }
      .manage-edit-roadmap-page .stage-item .fa-caret-right, .manage-edit-roadmap-page .stage-item .fa-caret-down {
        width: 1rem; }
      .manage-edit-roadmap-page .stage-item .fa-grip-vertical {
        cursor: -webkit-grab;
        cursor: grab; } }
  .manage-edit-roadmap-page .stage-item > div:first-child {
    justify-content: space-between; }
    .manage-edit-roadmap-page .stage-item > div:first-child:hover {
      cursor: pointer; }
    .manage-edit-roadmap-page .stage-item > div:first-child > div {
      position: relative; }
      .manage-edit-roadmap-page .stage-item > div:first-child > div > svg:first-child {
        position: absolute;
        left: -1rem;
        top: 6px;
        font-size: 0.7rem;
        vertical-align: baseline; }
    @media (min-width: 992px) {
      .manage-edit-roadmap-page .stage-item > div:first-child {
        justify-content: flex-start; } }

.manage-edit-roadmap-page .stage-description-notes {
  font-weight: normal;
  color: #3e3e3e; }

.manage-edit-roadmap-page .coach-notes {
  color: #f1561e; }

.manage-edit-roadmap-page .add-stage-button:disabled {
  opacity: 0.25; }

.manage-add-stage-competency-page .btn-save {
  color: #007bff;
  background-color: transparent;
  box-shadow: none; }

.manage-add-stage-competency-page .btn-cancel {
  background-color: transparent;
  box-shadow: none; }

.manage-add-stage-page .btn-save {
  color: #007bff;
  background-color: transparent;
  box-shadow: none; }

.manage-add-stage-page .btn-cancel {
  background-color: transparent;
  box-shadow: none; }

.manage-rename-stage-page .btn-save {
  color: #007bff;
  background-color: transparent;
  box-shadow: none; }

.manage-rename-stage-page .btn-cancel {
  background-color: transparent;
  box-shadow: none; }

.manage-add-stage-description-page .btn-save {
  color: #007bff;
  background-color: transparent;
  box-shadow: none; }

.manage-add-stage-description-page .btn-cancel {
  background-color: transparent;
  box-shadow: none; }

@media (min-width: 992px) {
  .manage-add-stage-description-page .form-control {
    border-color: #cccccc; }
    .manage-add-stage-description-page .form-control:active, .manage-add-stage-description-page .form-control:focus {
      border-color: #6E9FE0; }
  .manage-add-stage-description-page .btn-cancel {
    background-color: #f4f4f5;
    box-shadow: none; }
  .manage-add-stage-description-page .btn-save {
    float: right;
    background: #2f80ed;
    color: #fff; } }

@media (min-width: 992px) {
  .manage-add-stage-description-page .normal-width-container {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
    padding-bottom: 1rem;
    padding-left: 4rem;
    padding-right: 4rem;
    background: white;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px; } }

.manage-add-stage-coach-notes-page .btn-save {
  color: #007bff;
  background-color: transparent;
  box-shadow: none; }

.manage-add-stage-coach-notes-page .btn-cancel {
  background-color: transparent;
  box-shadow: none; }

@media (min-width: 992px) {
  .manage-add-stage-coach-notes-page .form-control {
    border-color: #cccccc; }
    .manage-add-stage-coach-notes-page .form-control:active, .manage-add-stage-coach-notes-page .form-control:focus {
      border-color: #6E9FE0; }
  .manage-add-stage-coach-notes-page .btn-save {
    float: right;
    background: #2f80ed;
    color: #fff; }
  .manage-add-stage-coach-notes-page .normal-width-container {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
    padding-bottom: 1rem;
    padding-left: 4rem;
    padding-right: 4rem;
    background: white;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px; } }

.manage-edit-roadmap-details-page .common-header .btn-save {
  color: #007bff;
  background-color: transparent;
  box-shadow: none; }

.manage-edit-roadmap-details-page .common-header .btn-cancel {
  background-color: transparent;
  box-shadow: none; }

.manage-edit-roadmap-details-form .roadmap-icon {
  border-radius: 50%;
  width: 4em;
  height: 4em; }

.manage-edit-roadmap-details-form .invalid-icon {
  font-size: 1rem; }
  .manage-edit-roadmap-details-form .invalid-icon.is-invalid {
    color: #dc3545; }

.manage-edit-roadmap-details-modal .modal-content {
  min-height: 16rem; }

.manage-edit-roadmap-details-modal.loading .modal-content .modal-header {
  border-bottom: none; }

.manage-competency-page img {
  max-width: 300px; }

.manage-competency-page .attachment .delete svg {
  color: #3e3e3e;
  font-size: 1.25rem;
  position: relative;
  left: 5px; }

.manage-competency-page .rc-slider-rail {
  background: linear-gradient(to right, #f1561e, #f5d844, #1bc573);
  height: 1rem;
  padding: 0;
  border-radius: 10px; }

.manage-competency-page .rc-slider-handle {
  display: none; }

.manage-competency-page .card--embed {
  display: flex;
  flex-direction: column; }
  .manage-competency-page .card--embed figure {
    flex-grow: 1; }
    .manage-competency-page .card--embed figure > div {
      flex-grow: 1; }

.manage-competency-page .card.item {
  justify-content: space-between;
  flex-direction: initial; }

.manage-competency-page .card .assessment-border {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem; }
  .manage-competency-page .card .assessment-border.red {
    border: 5px solid #f1561e; }
  .manage-competency-page .card .assessment-border.yellow {
    border: 5px solid #f5d844; }
  .manage-competency-page .card .assessment-border.green {
    border: 5px solid #1bc573; }

.manage-competency-page .card .assessment-input {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 0;
  width: 100%;
  outline: none; }
  .manage-competency-page .card .assessment-input--red:focus {
    box-shadow: 0 0 2px #f1561e; }
  .manage-competency-page .card .assessment-input--yellow:focus {
    box-shadow: 0 0 px #f5d844; }
  .manage-competency-page .card .assessment-input--green:focus {
    box-shadow: 0 0 2px #1bc573; }

@media (min-width: 992px) {
  .manage-competency-page .card {
    padding: 0;
    box-shadow: none; } }

.manage-competency-page .assessment-boxes-container {
  display: flex;
  flex-direction: column; }
  @media (min-width: 992px) {
    .manage-competency-page .assessment-boxes-container {
      flex-direction: row; }
      .manage-competency-page .assessment-boxes-container > div {
        flex-grow: 1;
        margin-left: 0.5rem;
        margin-right: 0.5rem; }
        .manage-competency-page .assessment-boxes-container > div:first-child {
          margin-left: 0; }
        .manage-competency-page .assessment-boxes-container > div:last-child {
          margin-right: 0; } }

.manage-competency-page .card.item {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  border-bottom: none !important;
  padding: 0.75rem; }

.manage-competency-page .slider-container {
  margin-bottom: 0.75rem; }

.manage-competency-page .ais-questions-container .ai-question-container {
  border: none !important;
  position: relative; }
  .manage-competency-page .ais-questions-container .ai-question-container span {
    width: calc(100% - 3rem); }
  .manage-competency-page .ais-questions-container .ai-question-container svg {
    position: absolute;
    right: 12px;
    top: 10px;
    border-radius: 10px;
    padding: 5px;
    width: 42px;
    height: 36px;
    font-size: 6px;
    color: #727272; }
    .manage-competency-page .ais-questions-container .ai-question-container svg:hover {
      cursor: pointer;
      background-color: #F4F4F5; }

.manage-competency-page .ais-questions-container .card {
  cursor: pointer;
  font-weight: normal;
  color: #3e3e3e;
  line-height: 31px; }
  .manage-competency-page .ais-questions-container .card:hover {
    opacity: 1; }

.manage-competency-page .supplemental-info-content {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  padding: 0.75rem; }
  .manage-competency-page .supplemental-info-content:hover {
    cursor: pointer; }

.manage-competency-page .intro-content .card--embed {
  background-color: white;
  border-radius: 10px;
  cursor: pointer; }

.manage-competency-page .coach-notes .card--embed {
  background-color: white;
  border-radius: 10px;
  cursor: pointer; }

.manage-competency-page .competency-title-clickable {
  border: 1px solid transparent;
  border-radius: 10px; }
  .manage-competency-page .competency-title-clickable:hover {
    border: 1px solid #ccc; }

@media (min-width: 992px) {
  .manage-competency-page .normal-width-container {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
    padding-bottom: 1rem;
    padding-left: 4rem;
    padding-right: 4rem;
    background: white;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px; }
  .manage-competency-page .assessment-slider-container {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    margin-top: 1rem;
    padding: 1rem;
    background-color: #fff;
    border-radius: 10px; }
  .manage-competency-page .card.item .reorder {
    font-size: 1rem;
    margin-right: 4px; } }

.supplemental-dialog .modal-mobile-slide-from-bottom .modal-dialog {
  min-height: 100%; }

.supplemental-dialog .modal-dialog {
  margin: 0;
  margin: auto; }

.supplemental-dialog .modal-body {
  padding: 0; }

.supplemental-dialog .modal-dialog-centered {
  min-height: 100%; }

.supplemental-dialog .modal-content {
  border: none;
  border-radius: 0;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem; }
  @media (min-width: 768px) {
    .supplemental-dialog .modal-content {
      border-radius: 1rem; } }
  .supplemental-dialog .modal-content .icon-header {
    position: relative; }
    .supplemental-dialog .modal-content .icon-header svg {
      position: absolute; }

.manage-add-competency-coach-notes-page .btn-save {
  color: #007bff;
  background-color: transparent;
  box-shadow: none; }

.manage-add-competency-coach-notes-page .btn-cancel {
  background-color: transparent;
  box-shadow: none; }

.manage-add-competency-intro-content-page .btn-save {
  color: #007bff;
  background-color: transparent;
  box-shadow: none; }

.manage-add-competency-intro-content-page .btn-cancel {
  background-color: transparent;
  box-shadow: none; }

.manage-add-competency-intro-content-page .ck-editor__editable {
  min-height: 12rem; }

.manage-rename-competency-page .btn-save {
  color: #007bff;
  background-color: transparent;
  box-shadow: none; }

.manage-rename-competency-page .btn-cancel {
  background-color: transparent;
  box-shadow: none; }

.manage-add-competency-action-item-page .btn-save {
  color: #007bff;
  background-color: transparent;
  box-shadow: none; }

.manage-add-competency-action-item-page .btn-cancel {
  background-color: transparent;
  box-shadow: none; }

.manage-add-competency-action-item-page .ck-editor__editable {
  min-height: 12rem; }

.manage-add-competency-action-item-page .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px; }

.manage-add-competency-action-item-page .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable {
  min-height: 12rem; }
  .manage-add-competency-action-item-page .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; }

@media (min-width: 992px) {
  .manage-add-competency-action-item-page .form-control {
    border-color: #cccccc; }
    .manage-add-competency-action-item-page .form-control:active, .manage-add-competency-action-item-page .form-control:focus {
      border-color: #6E9FE0; }
  .manage-add-competency-action-item-page .btn-cancel {
    background-color: #f4f4f5;
    box-shadow: none; }
  .manage-add-competency-action-item-page .btn-save {
    float: right;
    background: #2f80ed;
    color: #fff; } }

@media (min-width: 992px) {
  .manage-add-competency-action-item-page .normal-width-container {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
    padding-bottom: 1rem;
    padding-left: 4rem;
    padding-right: 4rem;
    background: white;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px; }
  .manage-add-competency-action-item-page .assessment-slider-container {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    margin-top: 1rem;
    padding: 1rem;
    background-color: #fff;
    border-radius: 10px; }
  .manage-add-competency-action-item-page .card.item .reorder {
    font-size: 1rem;
    margin-right: 4px; } }

.manage-add-competency-global-questions-page .btn-save {
  color: #007bff;
  background-color: transparent;
  box-shadow: none; }

.manage-add-competency-global-questions-page .btn-cancel {
  background-color: transparent;
  box-shadow: none; }

@media (min-width: 992px) {
  .manage-add-competency-global-questions-page .form-control {
    border-color: #cccccc; }
    .manage-add-competency-global-questions-page .form-control:active, .manage-add-competency-global-questions-page .form-control:focus {
      border-color: #6E9FE0; }
  .manage-add-competency-global-questions-page .btn-cancel {
    background-color: #f4f4f5;
    box-shadow: none; }
  .manage-add-competency-global-questions-page .btn-save {
    float: right;
    background: #2f80ed;
    color: #fff; } }

@media (min-width: 992px) {
  .manage-add-competency-global-questions-page .normal-width-container {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
    padding-bottom: 1rem;
    padding-left: 4rem;
    padding-right: 4rem;
    background: white;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px; } }

.manage-add-competency-supplemental-content-page .btn-save {
  color: #007bff;
  background-color: transparent;
  box-shadow: none; }

.manage-add-competency-supplemental-content-page .btn-cancel {
  background-color: transparent;
  box-shadow: none; }

.manage-add-competency-supplemental-content-page .ck-editor__editable {
  min-height: 12rem; }

.manage-add-competency-supplemental-content .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px; }

.manage-add-competency-supplemental-content .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable {
  min-height: 12rem; }
  .manage-add-competency-supplemental-content .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; }

.manage-add-competency-intro-content .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px; }

.manage-add-competency-intro-content .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable {
  min-height: 12rem; }
  .manage-add-competency-intro-content .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; }

.manage-rename-competency {
  padding-top: .4rem;
  padding-bottom: .4rem; }
  .manage-rename-competency .competency-name-input {
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }

.manage-rename-stage {
  flex: 0.5 1; }
  .manage-rename-stage .stage-name-input {
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }

